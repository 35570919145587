const Pt = {
    btn: {
        saveName: 'Salvar nome' ,
        yes: 'Sim',
        no: 'Não',
        add: 'Adicionar',
        viewCart: 'Ver meu carrinho',
        alertOk: 'Sim, eu entendo',
        confirmOrder:'Confirme seu pedido',
        shopping: `Vamos às compras!`,
        status: {
            orderStatus0:'Atualizar status do pedido',
            orderStatus1:'Verifique o status da entrega',
            orderStatus9:'Encomende de outros comerciantes'
        }
    },
    text: {
        saveName: 'Olá, por favor digite seunome antes de continuar...',
        item: 'artigos',
        empty: 'Esgotado',
        orderList: 'LISTA DE ENCOMENDAS',
        billDetail: 'DETALHE DA FATURA',
        totalPrice: 'Preço Total',
        totalDisc: 'Desconto Total',
        platformFee: 'Taxa de Plataforma',
        deliveryFee: 'Taxa de entrega',
        toBePaid: 'Ser pago',
        emptyCart: 'Seu carrinho de compras ainda está vazio :(',
        orderPlaced: {
            title: 'ordem feita com sucesso',
            desc: 'Esperando o comerciante confirmar seu pedido'
        },
        merchantCancel: {
            title: 'Seu pedido é cancelado',
            desc: 'Comerciante cancela seu pedido'
        },
        merchantconfirm: {
            title: 'Seu pedido está confirmado',
            desc: 'Mercante confirma seu pedido'
        },
        itemPickedUp: {
            title: 'Seu pedido está sendo enviado',
            desc: 'Obrigado por usar o serviço máximo'
        },
        completeStatus: {
            title: 'Seu pedido está completo',
            desc: 'Obrigado por usar o serviço máximo'
        },
        userCancel: {
            title: 'Seu pedido é cancelado',
            desc: 'O usuário cancela seu pedido'
        },
        buyerName: 'Nome do comprador',
        receiveAt: 'Receber em',
        status: 'Status',
        paymentType: 'Tipo de pagamento',
        cash: 'Dinheiro',
        note: 'Observação: ',
        noNote: 'Sem nota',
        noteMerchant: 'Nota para comerciante'
    },
    label:{
        user_name: 'Seu nome: '
    },
    placeholder: {
        name: 'Insira seu nome',
        searchHome: 'Pesquise comerciante ou item ...',
        searchCategory: "Pesquise Merchant",
        noteItemDetail: 'Escreva aqui para notas especiais',
        searchItem: 'Item de pesquisa ...',
        noteMerchant: "Escreva seu comentário ou sugestão para o comerciante" 
    },
    alert:{
        orderCancel: 'Este pedido é cancelado',
        nonPartner: 'Não parceiro',
        noteNonPartner: 'Este comerciante não é um parceiro máximo oficial, a disponibilidade do produto e a precisão dos preços podem mudar e diferir das condições reais. Continue?',
        cart: 'Carrinho',
        cartEmpty: 'Somente há apenas uma quantidade para este item/menu no seu carrinho, diminuindo a quantidade significa remover itens do seu carrinho. Continue?',
        alert: 'Alert',
        noteProcessOrder: 'O servidor está processando sua solicitação, aguarde ... Se o processo demorar muito, reinicie seu aplicativo ou verifique sua conexão com a Internet.',
        confirmOrder: 'Confirmar pedido',
        noteConfirmOrder: 'Tem certeza de que deseja encaminhar seu pedido? Por favor, verifique novamente antes de confirmar o pedido',
        noteCountDeliveryPrice: 'Ocorreu um erro ao calcular o custo de envio, feche a inscrição e reabre -o para continuar ..',
        noteDeliveryPrice: 'Ocorreu um erro ao recuperar sua localização para cálculos de postagem, feche o aplicativo e reabre -o e verifique se o seu GPS está em frente.',
        noteNonPartnerReceipt: 'Este comerciante não é um parceiro máximo oficial, a disponibilidade de itens e os preços listados podem variar. Faça um pagamento de acordo com o preço declarado no recibo.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Começar',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Mercador de categoria",
            History: 'Foods&Goods - História',
            DetailCart: "Foods&Goods - Detalhes do carrinho",
            afterOrder: 'Foods&Goods - Depois do pedido',
            HistoryDetail: 'Foods&Goods - Detalhes da história',
        },
        desc: {
            start:'Começar',
            Home: "Foods&Goods",
            Category: "Lista de comerciantes por categoria",
            History: 'Lista de transações que você fez',
            DetailCart: "Detalhes do item em seu carrinho",
            afterOrder: 'Depois do pedido',
            HistoryDetail: 'Detalhes da história',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'História'
    },
    loading: 'Carregando ...',
    data: {
        noDataMerchant: 'Não há comerciante encontrado', 
        DataMerchant: 'Comerciante encontrado', 
    },
    bottomNav: {
        Home: 'Casa',
        Explore: 'Explorar',
        Promo: 'Promoção',
        History: 'História',
    },
    status: {
        Open: 'Aberto',
        Close: 'Perto',
        case0: 'Aguardando aprovação da loja',
        case1: 'Pedido aprovado pela loja',
        case2: 'Ordem trazida pela máxima do motorista',
        case9: 'Pedido cancelado',
        caseNotFound: 'Status de pedido não encontrado',
        orderStatus0 : 'Esperando',
        orderStatus1 : 'Aprovado pelo comerciante',
        orderStatus2 : 'Pego pelo motorista',
        orderStatus11 : 'Concluído',
        orderStatus99 : 'Cancelado',
        orderStatusNotFound : 'Status não encontrado'
    }
}

export default Pt