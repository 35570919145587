import React from 'react'
import PropType from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { GoPlus, GoDash } from "react-icons/go";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removeFromCart, addQtyItem, removeQtyItem } from '../../application/actions/cartActions';

import { useCart } from '../../application/hooks';

import { formatCurrency } from '../../miscellaneous/helpers/utils';

import './Discount.css'
import { ItemImage } from '../../assets';
import { t } from '../../miscellaneous/language';

const MerchantDetailDiscountItem = ({ item, detailFunction }) => {
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        merchants: state.merchants.details
    }), shallowEqual);

    const { addToCart, checkItemOnCart, itemQtyCounter } = useCart(item.id);

    const onItemAddToCart = (id) => {
        if (itemQtyCounter(id) >= 1) {
            dispatch(addQtyItem(id));
        } else {
            addToCart({ ...item, qty: 1, orderDesc: null}, store.merchants);
        }
    }
    
    const onItemRemoveFromCart = (id) => {
        if (itemQtyCounter(id) > 1) {
            dispatch(removeQtyItem(id));
        } else {
            // confirmAlert({
            //     title: 'Keranjang',
            //     message: "Barang/menu tersisa satu buah dalam keranjang, mengurangi kuantitas akan menghapus barang/menu dari keranjang.. Lanjutkan?",
            //     buttons: [
            //       {
            //         label: 'Ya',
            //         onClick: () => {
            //             dispatch(removeFromCart(id));
            //         }
            //       },
            //       {
            //         label: 'Tidak'
            //       }
            //     ]
            // });
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="alert">
                            <h1>{t.alert.alert}</h1>
                            <p>{t.alert.cartEmpty}</p>
                            <div className="alert-button-container">
                                <button
                                    class="yes-button" 
                                    onClick={ () => {
                                        dispatch(removeFromCart(id));
                                        onClose();
                                    }}
                                >
                                    {t.btn.yes}
                                </button>
                                <button 
                                    class="no-button" 
                                    onClick={onClose}
                                >
                                    {t.btn.no}
                                </button>
                            </div>
                        </div>
                    )
                }
            })
        }
    }

    return (
        <div className="discount-card-container merchant-detail-discount-card-container" onClick={(e) => detailFunction(item, "None")}>
            <div className="discount-img-container">
                <img src={item.image} alt="" className="discount-img" onError={(event) => event.target.src = ItemImage}/>
                <div className="overlay"></div>
            </div>
            <div className="discount-card-desc merchant-detail-discount-card-desc">
                <span className="discount-card-item-name">{item.name}</span>
                <span className="discount-card-item-price">{formatCurrency(item.total)}</span>
            </div>
            <div className="merchant-menu-counter merchant-detail-discount-item-counter">
                {item.is_empty !== 1 ? '' : checkItemOnCart(item.id) ? (
                    <>
                        <button className="button-counter" onClick={(e) => {
                            e.stopPropagation();
                            onItemRemoveFromCart(item.id);
                        }}>
                            <GoDash className="button-counter-icon" />
                        </button>
                        <span className="menu-counted">{itemQtyCounter(item.id) === undefined ? 0 : itemQtyCounter(item.id)}</span>
                        <button className="button-counter" onClick={(e) => {
                            e.stopPropagation();
                            onItemAddToCart(item.id)
                        }}>
                            <GoPlus className="button-counter-icon" />
                        </button>
                    </>)
                    : <button className="button-add-item" onClick={(e) => {
                        e.stopPropagation();
                        onItemAddToCart()
                        }}>
                            {t.btn.add}
                        </button>
                    }
                {/* BISA ADD ITEM DI DISCOUNT ITEM SLIDER */}
            </div>
        </div>
    )
}

MerchantDetailDiscountItem.propTypes = {
    item: PropType.shape({
        image: PropType.string,
        name: PropType.string
    }).isRequired
}

export default MerchantDetailDiscountItem
