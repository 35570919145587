import React from 'react'
import { Helmet } from 'react-helmet'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { setRequestStatus } from '../../application/actions/miscActions';
import { getMerchantDetails } from '../../application/actions/merchantActions';

import { useDidMount } from '../../application/hooks';
import { confirmAlert } from 'react-confirm-alert';

import { 
    BottomSpace,
    MerchantDetailTopNav,
    MerchantItemSearch,
    MerchantDetailButton,
} from '../components/common'

import { 
    MerchantDetailDiscountContainer,
    MerchantDetailItem,
    MerchantMenuContainer,
    ItemDetail
 } from '../'

import '../../App.css'
import { useHistory } from 'react-router';
import { t } from '../../miscellaneous/language';

const MerchantDetailContainer = ({ match }) => {
    const { merchantId } = match.params;
    const history = useHistory();

    const [clickedDetail, setClickedDetail] = React.useState(false);
    const [detailData, setDetailData] = React.useState();
    const [selectedItemCat, setSelectedItemCat] = React.useState("None");

    const dispatch = useDispatch();
    const didMount = useDidMount(true);

    const store = useSelector((state) => ({
        keyword: state.merchants.keyword,
        merchant_details: state.merchants.details,
        searched_merchant_items: state.merchants.searchedMerchantItems,
        requestStatus: state.app.requestStatus,
        isLoading: state.app.loading
    }), shallowEqual);

    const handleDetailClick = async (itemDetail, itemCat) => {
        if(itemDetail && itemCat) {
            await window.scrollTo(0, 0);
            setDetailData(itemDetail);
            setSelectedItemCat(itemCat);
            await dispatch(getMerchantDetails(itemDetail.merchants_id));
        }
        else {
            setDetailData();
            setSelectedItemCat("None");
        }
        setClickedDetail(!clickedDetail);
    }

    const checkMerchhantPartner = () => {
        if(store.merchant_details?.id == merchantId) {
            if(store.merchant_details?.partner_level == 0) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="alert">
                                <h1>{t.alert.nonPartner}</h1>
                                <p>{t.alert.noteNonPartner}</p>
                                <div className="alert-button-container">
                                    <button
                                        className="yes-button" 
                                        onClick={onClose}
                                    >
                                        {t.btn.yes}
                                    </button>
                                    <button 
                                        class="no-button" 
                                        onClick={() => {
                                            history.push(`/home`)
                                            onClose();
                                        }}
                                    >
                                        {t.btn.no}
                                    </button>
                                </div>
                            </div>
                        )
                    }
                })
            }
        }
    }

    React.useEffect(() => {
        if (didMount && store.merchant_details?.id != merchantId) {
            dispatch(getMerchantDetails(merchantId));
        }
        checkMerchhantPartner();
    }, [didMount, merchantId, clickedDetail, selectedItemCat, detailData, store.keyword, store.merchant_details, dispatch]);
    
    React.useEffect(() => () => {
        dispatch(setRequestStatus(''));
    // eslint-disable-next-line 
    }, []);

    return (
        <>
            <Helmet>
                <title>{`Foods&Goods - ${store.merchant_details?.name}`}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={store.merchant_details?.name} />
            </Helmet>
            <MerchantDetailTopNav title={store.merchant_details?.name} isClicked={clickedDetail} handleDetailClick={handleDetailClick}/>
            <MerchantDetailItem merchant_details={store.merchant_details}/>
            {clickedDetail ? 
            <>
                <ItemDetail item={detailData} itemCat={selectedItemCat}/>
                <BottomSpace class="gray-bottom-space" /> 
            </>    
            : 
            <>
                <MerchantItemSearch />
                {store.keyword == null || store.keyword === '' ? 
                <>
                    <MerchantDetailDiscountContainer items={store.merchant_details.merchant_items} detailFunction={handleDetailClick}/>
                    <MerchantMenuContainer items={store.merchant_details.merchant_item_category} detailFunction={handleDetailClick}/>
                    <BottomSpace />
                </>
                : 
                <>
                    <MerchantDetailDiscountContainer items={store.searched_merchant_items?.merchant_items} detailFunction={handleDetailClick}/>
                    <MerchantMenuContainer items={store.searched_merchant_items?.merchant_item_category} detailFunction={handleDetailClick}/>
                    <BottomSpace />
                </>}
            </>}
            <MerchantDetailButton />
        </>
    )
}

export default MerchantDetailContainer
