import React from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { calculateTotal, formatCurrency } from '../../../../miscellaneous/helpers/utils';

import {
    Cart
} from '../../../../assets'

import './Button.css';
import { t } from '../../../../miscellaneous/language';

const MerchantDetailButton = () => {    
    const history = useHistory();

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const countSumOfQty = () => {
        let total = 0;
        for (var i in cart.items) {
            total += cart.items[i].qty;
        }   
        return total;     
    }

    return (
        <>
            {cart.items?.length === 0 ? <div className="none"></div> :
                <div className="merchant-detail-button-container" onClick={(e) => {history.push("/cart/detail")}}>
                    <span className="merchant-detail-item-selected">{`${cart.items?.length === 0 ? 0 : countSumOfQty()} ${t.text.item}`} | {formatCurrency(calculateTotal(cart.items?.map((item) => item.total * item.qty)))}</span>
                    <button className="merchant-detail-button">{t.btn.viewCart}<Cart id="cart-small-icon" /></button>
                </div>
            }
        </>
    )
}

export default MerchantDetailButton
