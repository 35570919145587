import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const usePlatformFeeGenerator = (id, name, lat, lon, city, host) => {
    // console.log(id, name, lat, lon, city, host)
    const [platformFee, setPlatformFee] = React.useState(null);

    const fetcher = async (url) => await axios.get(url, {
        params: {
            userID: id, 
            name: name, 
            lat: lat,
            lon: lon, 
            city: city, 
            host: host
        }
    });

    const {
        data,
        error,
        mutate
    } = useSWR('https://brmarket-api.taximaxim.com/api/v1/home/platformfee', fetcher);

    React.useEffect(() => {
        console.log(data)
        if (data) {
            setPlatformFee(data.data.result);
        }
        if (error) {
            setPlatformFee(null);
        }
        // eslint-disable-next-line
    },[data, error]);

    return {
        platformFee,
        mutate
    }
}

export default usePlatformFeeGenerator;
