import React from 'react'
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './Category.css'
import { clearSearchCategoryState } from '../../application/actions/merchantActions';

const CategoryItem = ({ item }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className="category-card" onClick={async () => {
            if (item.id) {
                await dispatch(clearSearchCategoryState());
                history.push(`/category/${item.id}/${item.name}/merchants`);
            }
        }}>
            <img src={item.image} alt="" className="category-img" />
            <span className="category-name">
                {item.name}
            </span>
        </div>
    )
}

CategoryItem.propTypes = {
    item: PropType.shape({
        image: PropType.string,
        name: PropType.string
    }).isRequired
}

export default CategoryItem
