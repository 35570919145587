import React from 'react'
import MerchantDetailDiscountItem from './MerchantDetailDiscountItem'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import { t } from '../../miscellaneous/language';

const MerchantDetailDiscountContainer = ({ items, detailFunction }) => {
    
    const SwiperBreakpoints = {
        500: {
            slidesPerView: 3,
            spaceBetween: 9,
        },
        425: {
            slidesPerView: 3,
            spaceBetween: 9
        },
        375: {
            slidesPerView: 2,
            spaceBetween: 9
        },
        320: {
            slidesPerView: 2,
            spaceBetween: 9
        },
        280: {
            slidesPerView: 2,
            spaceBetween: 9
        }
    }
    
    return (
        <div className="discount-container">
            <Swiper 
                breakpoints={SwiperBreakpoints}
            >
                {!items ? <span>{t.loading}</span> : items?.map((discItem) => (
                    <SwiperSlide key={discItem.id}>
                        <MerchantDetailDiscountItem
                            detailFunction={detailFunction}
                            item={discItem}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>        
        </div>
    )
}

export default MerchantDetailDiscountContainer
