import React from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { setLoading } from "../../application/actions/miscActions";
import {
  filterNearestMerchantCategory,
  searchMerchantCategory,
  getCategoryMerchants,
  filterDistrictMerchantCategory,
  setIsLoadMore,
  // clearCategoryMerchantState,
  // clearFilterNearMeCategoryState,
  // clearFilterDistrictCategoryState,
} from "../../application/actions/merchantActions";

import "../components/common/Filter/Filter.css";
import "./Merchant.css";
import { t } from "../../miscellaneous/language";

const MerchantListCategory = (props) => {
  const { merchants, district, children, categoryId } = props;
  console.log(props);

  const [pageSize, setPageSize] = React.useState(1);
  const [pageNear, setPageNear] = React.useState(1);
  const [pageSearch, setPageSearch] = React.useState(1);
  const [pageDistrict, setPageDistrict] = React.useState(1);

  const [currentDistrict, setCurrentDistrict] = React.useState();

  const dispatch = useDispatch();

  const fetchNearestMerchants = (page) =>
    dispatch(filterNearestMerchantCategory(categoryId, page));
  const fetchSearchMerchants = (keyword, page) =>
    dispatch(searchMerchantCategory(keyword, categoryId, page));
  const fetchMerchants = (page) =>
    dispatch(getCategoryMerchants(categoryId, page));
  const fetchDistrictMerchants = (district, page) =>
    dispatch(filterDistrictMerchantCategory(district, categoryId, page));
  const setLoadMore = (status) => dispatch(setIsLoadMore(status));

  React.useEffect(() => {
    if (
      merchants.filterNearMeCategory.items.length === 0 &&
      merchants.type === "FILTER_NEAR_ME"
    ) {
      fetchNearestMerchants();
    } else if (
      merchants.category.items.length === 0 &&
      merchants.type === "DEFAULT"
    ) {
      fetchMerchants();
    }

    return () => dispatch(setLoading(false));
    // eslint-disable-next-line
  }, [
    merchants.filterNearMeCategory.items.length,
    merchants.category.items.length,
    merchants.type,
    pageSize,
    pageSearch,
    pageDistrict,
    pageNear,
    dispatch,
  ]);

  const fetchNextData = () => {
    if (!merchants.isLoadMore) {
      if (dataHasMore()) {
        switch (merchants.type) {
          case "SEARCH":
            setLoadMore(true);
            setPageSearch(pageSearch + 1);
            fetchSearchMerchants(merchants.keyword, pageSearch + 1);
            break;
          case "DEFAULT":
            setLoadMore(true);
            setPageSize(pageSize + 1);
            fetchMerchants(pageSize + 1);
            break;
          case "FILTER_DISTRICT":
            if (currentDistrict === district?.id || currentDistrict === null) {
              setCurrentDistrict(district?.id);
              setLoadMore(true);
              setPageDistrict(pageDistrict + 1);
              fetchDistrictMerchants(district?.id, pageDistrict + 1);
            } else {
              setCurrentDistrict(district?.id);
              setPageDistrict(2);
              setLoadMore(true);
              fetchDistrictMerchants(district?.id, 2);
            }
            break;
          default:
            setLoadMore(true);
            setPageNear(pageNear + 1);
            fetchNearestMerchants(pageNear + 1);
        }
      }
    }
  };

  const lengthData = () => {
    let length = 0;
    switch (merchants.type) {
      case "SEARCH":
        length = merchants.searchedItemsCategory.items.length;
        break;
      case "DEFAULT":
        length = merchants.category.items.length;
        break;
      case "FILTER_DISTRICT":
        length = merchants.filterDistrictCategory.items.length;
        break;
      default:
        length = merchants.filterNearMeCategory.items.length;
    }
    return length;
  };

  const dataHasMore = () => {
    let isMore = false;
    switch (merchants.type) {
      case "SEARCH":
        isMore =
          merchants.searchedItemsCategory.items.length !==
          merchants.searchedItemsCategory.total;
        break;
      case "DEFAULT":
        isMore = merchants.category.items.length !== merchants.category.total;
        break;
      case "FILTER_DISTRICT":
        isMore =
          merchants.filterDistrictCategory.items.length !==
          merchants.filterDistrictCategory.total;
        break;
      default:
        isMore =
          merchants.filterNearMeCategory.items.length !==
          merchants.filterNearMeCategory.total;
    }
    return isMore;
  };

  return (
    <>
      <InfiniteScroll
        dataLength={lengthData}
        next={fetchNextData}
        hasMore={dataHasMore}
        scrollThreshold={1}
        loader={dataHasMore ? <></> : <h4>Loading...</h4>}>
        <div className="merchant-list-count">
          <span>
            {merchants.type === "SEARCH"
              ? lengthData()
              : merchants.type === "DEFAULT"
              ? lengthData()
              : merchants.type === "FILTER_DISTRICT"
              ? lengthData()
              : lengthData()}{" "}
            {t.data.DataMerchant}
          </span>
        </div>
        {merchants.type === "SEARCH" &&
        merchants.searchedItemsCategory.items.length === 0 ? (
          <span>
            <center>{t.data.noDataMerchant}</center>
          </span>
        ) : merchants.type === "DEFAULT" &&
          merchants.category.items.length === 0 ? (
          <span>
            <center>{t.data.noDataMerchant}</center>
          </span>
        ) : merchants.type === "FILTER_DISTRICT" &&
          merchants.filterDistrictCategory.items.length === 0 ? (
          <span>
            <center>{t.data.noDataMerchant}</center>
          </span>
        ) : 
          children.props.merchants.length === 0 && merchants.filterNearMeCategory.items.length === 0 ? 
          (
            <span>
              <center>{t.data.noDataMerchant}</center>
            </span>
          )
          :children
        }
      </InfiniteScroll>
    </>
  );
};

MerchantListCategory.defaultProps = {
  requestStatus: null,
};

MerchantListCategory.propTypes = {
  merchants: PropType.object.isRequired,
  isLoading: PropType.bool.isRequired,
  requestStatus: PropType.string,
  district: PropType.object,
  children: PropType.oneOfType([PropType.arrayOf(PropType.node), PropType.node])
    .isRequired,
};

export default MerchantListCategory;
