const Ph = {
    btn: {
        saveName: 'I-save ang Pangalan' ,
        yes: 'Oo',
        no: 'Hindi',
        add: 'Idagdag',
        viewCart: 'Tingnan ang aking cart',
        alertOk: 'Oo, naiintindihan ko',
        confirmOrder:'kumpirmahin mo ang iyong order',
        shopping: `Shopping tayo!`,
        status: {
            orderStatus0:'I-refresh ang katayuan ng order',
            orderStatus1:'Suriin ang katayuan ng paghahatid',
            orderStatus9:'Order sa ibang merchant'
        }
    },
    text: {
        saveName: 'Kumusta, mangyaring ilagay ang iyong pangalan bago magpatuloy...',
        item: 'aytem',
        empty: 'Sold Out',
        orderList: 'LISTAHAN NG ORDER',
        billDetail: 'DETALYE NG BILL',
        totalPrice: 'Kabuuan ng Presyo',
        totalDisc: 'Kabuuan ng Diskwento',
        platformFee: 'Bayad sa Platform',
        deliveryFee: 'Bayarin sa Paghahatid',
        toBePaid: 'Babayaran',
        emptyCart: 'Walang laman pa rin ang iyong shopping cart :(',
        orderPlaced: {
            title: 'Matagumpay na nag -order',
            desc: 'Naghihintay para sa mangangalakal upang kumpirmahin ang iyong order'
        },
        merchantCancel: {
            title: 'Nakansela ang iyong order',
            desc: 'Merchant cancels ang iyong order'
        },
        merchantconfirm: {
            title: 'nakumpirma ang iyong order',
            desc: 'Kinukumpirma ng Merchant ang iyong order'
        },
        itemPickedUp: {
            title: 'Ipinadala ang iyong order',
            desc: 'Salamat sa paggamit ng serbisyo ng Maxim'
        },
        completeStatus: {
            title: 'Kumpleto ang iyong order',
            desc: 'Salamat sa paggamit ng serbisyo ng Maxim'
        },
        userCancel: {
            title: 'Nakansela ang iyong order',
            desc: 'Kinakansela ng gumagamit ang iyong order'
        },
        buyerName: 'Pangalan ng Mamimili',
        receiveAt: 'Tumanggap sa',
        status: 'Katayuan',
        paymentType: 'Uri ng Pagbabayad',
        cash: 'Cash',
        note: 'Tandaan: ',
        noNote: 'Walang tala',
        noteMerchant: 'Tandaan para sa Merchant'
    },
    label:{
        user_name: 'Ang pangalan mo: '
    },
    placeholder: {
        name: 'I -input ang iyong pangalan',
        searchHome: 'Maghanap ng Merchant o Item ...',
        searchCategory: "Maghanap ng Merchant",
        noteItemDetail: 'Sumulat dito para sa mga espesyal na tala',
        searchItem: 'Paghahanap ng item ...',
        noteMerchant: "Isulat ang iyong puna o mungkahi para sa mangangalakal" 
    },
    alert:{
        orderCancel: 'Kinansela ang order na ito',
        nonPartner: 'Hindi kasosyo',
        noteNonPartner: 'Ang mangangalakal na ito ay hindi isang opisyal na kasosyo sa Maxim, ang pagkakaroon ng produkto at kawastuhan ng presyo ay maaaring magbago at naiiba sa aktwal na mga kondisyon .. Magpatuloy?',
        cart: 'Cart',
        cartEmpty: 'May isang dami lamang na naiwan para sa item/menu na ito sa iyong cart, ang pagbawas ng dami ay nangangahulugang pag -alis ng mga item sa iyong cart .. Magpatuloy?',
        alert: 'Alerto',
        noteProcessOrder: 'Pinoproseso ng server ang iyong kahilingan, mangyaring maghintay ... Kung ang proseso ay tumatagal ng masyadong mahaba, mangyaring i -restart ang iyong aplikasyon o suriin ang iyong koneksyon sa internet ..',
        confirmOrder: 'Kumpirmahin ang order',
        noteConfirmOrder: 'Sigurado ka bang nais mong ipasa ang iyong order? Mangyaring suriin muli bago kumpirmahin ang order',
        noteCountDeliveryPrice: 'May naganap na error habang kinakalkula ang gastos sa pagpapadala, mangyaring isara ang application at buksan muli ito upang magpatuloy ..',
        noteDeliveryPrice: 'May naganap na error habang nakukuha ang iyong lokasyon para sa mga kalkulasyon ng selyo, mangyaring isara ang application at buksan muli ito at tiyaking magpatuloy ang iyong GPS ..',
        noteNonPartnerReceipt: 'Ang mangangalakal na ito ay hindi isang opisyal na kasosyo sa Maxim, ang pagkakaroon ng item at mga presyo na nakalista ay maaaring magkakaiba. Mangyaring gumawa ng isang pagbabayad ayon sa presyo na nakasaad sa resibo.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Magsimula',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Merchant ng kategorya",
            History: 'Foods&Goods - Kasaysayan',
            DetailCart: "Foods&Goods - Detalye ng Cart",
            afterOrder: 'Foods&Goods - Pagkatapos ng order',
            HistoryDetail: 'Foods&Goods - Detalye ng Kasaysayan',
        },
        desc: {
            start:'Magsimula',
            Home: "Foods&Goods",
            Category: "Listahan ng Merchant ayon sa kategorya",
            History: 'Listahan ng mga transaksyon na nagawa mo',
            DetailCart: "Mga detalye ng item sa iyong cart",
            afterOrder: 'Pagkatapos ng order',
            HistoryDetail: 'Detalye ng Kasaysayan',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'Kasaysayan'
    },
    loading: 'Naglo -load ...',
    data: {
        noDataMerchant: 'Walang nahanap na mangangalakal', 
        DataMerchant: 'Natagpuan ang Merchant', 
    },
    bottomNav: {
        Home: 'Home',
        Explore: 'Galugarin',
        Promo: 'Promo',
        History: 'Kasaysayan',
    },
    status: {
        Open: 'Buksan',
        Close: 'Malapit',
        case0: 'Naghihintay ng pag -apruba ng tindahan',
        case1: 'Order na naaprubahan ng tindahan',
        case2: 'Order na dinala ni Driver Maxim',
        case9: 'Kinansela ang order',
        caseNotFound: 'Hindi natagpuan ang katayuan ng order',
        orderStatus0 : 'Naghihintay',
        orderStatus1 : 'Inaprubahan ng Merchant',
        orderStatus2 : 'Kinuha ng driver',
        orderStatus11 : 'Nakumpleto',
        orderStatus99 : 'Kanselahin',
        orderStatusNotFound : 'Hindi natagpuan ang katayuan'
    }
}

export default Ph