import React from 'react'
import PropType from 'prop-types';

import {
    Star, 
    District,
    PriceLabel, 
    MerchantImage
} from '../../assets/'

import './Merchant.css'

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';

const MerchantItem = ({ merchants }) => {
    const { items } = merchants
    const history = useHistory();

    return (
        <>
            {items.map((merchant, index) => (
                <div key={index} className="merchant-list-card " onClick={(e) => { merchant.is_closed === 1 ? alert('Merchant sedang tutup!') : history.push(`/merchant/${merchant.id}`) }}>
                    {merchant.is_closed === 0 ? 
                        <div className="img-container">
                            <img src={merchant.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                            {/* <div className="merchant-list-img-status">status</div> */}
                        </div>
                        :
                        <div className="merchant-list-img" style={{backgroundImage:"url(" + merchant.image + ")", marginRight:10}} >
                           <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}>{merchant.is_closed === 0 ? "Buka": "Tutup"}</p>
                        </div>
                    }
                    <div className="merchant-list-description-container">
                        <span className="merchant-list-name">{merchant.name}</span>
                        <span className="merchant-list-desc">{merchant.desc}</span>
                        <div className="border"></div>
                        <div className="merchant-list-detail-container">
                            <div className="merchant-list-detail"><span><Star id="merchant-icon-info" fill="#FF6000" /> {merchant.avg_rating}</span></div>
                            <div className="merchant-list-detail"><span><District id="merchant-icon-info" /> {merchant.district}</span></div>
                            <div className="merchant-list-detail"><span><PriceLabel id="merchant-icon-info" /> {formatCurrencyKStyle(merchant.min)} - {formatCurrencyKStyle(merchant.max)}</span></div>
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? "Halal" : "Non-Halal"}</span></div> : ''} */}
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? "halal" : "non-halal"}</span></div> : ''} */}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

MerchantItem.propTypes = {
    merchants: PropType.object.isRequired
}

export default MerchantItem
