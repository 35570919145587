import  { 
    SEND_ORDER,
    REFRESH_ORDER_STATUS
} from '../../../miscellaneous/constants';

import {
    select,
    call,
    put
} from 'redux-saga/effects';

import { actionMessage } from '../../../miscellaneous/helpers/utils';

import { setLoading, setRequestStatus } from '../../actions/miscActions';
import { 
    sendOrderSuccess, refreshOrderStatusSuccess
} from '../../actions/cartActions';

import { 
    sendOrderAPI, getOrderDetails
} from '../../../infrastructure/services/api/Cart';

import { history } from '../../routers/Router';

// import Swal from 'sweetalert2'

// const Toast = Swal.mixin({
//     toast: true,
//     position: 'top',
//     timer: 5000,
//     timerProgressBar: true
// })

export const getUserData = ((state) => state.auth.userData);

function* initRequest() {
    yield put(setLoading(true));
    yield put(setRequestStatus(null));
}

function* handleError(e) {
    yield put(setLoading(false));
    yield put(setRequestStatus(e?.message || 'Order failed!'));
    console.log('ERROR: ', e);
}

function* handleAction(location, message, status) {
    if (location) yield call(history.push, location);
    yield call(actionMessage, message, status);
}

function* cartSaga({ type, payload }) {
    const userData = yield select(getUserData);

    switch (type) {
        case SEND_ORDER:
            try {
                yield initRequest();

                const response = yield call(sendOrderAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city, 
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.total_item,
                    payload.total_price,
                    payload.total_discount,
                    payload.delivery_price,
                    payload.total,
                    payload.notes,
                    payload.merchants_id,
                    payload.order_items,
                    payload.platform_fee

                );
                
                console.log(response);

                if(response.success) {
                    yield put(sendOrderSuccess(response.data));
                    yield put(setRequestStatus(''));
                    history.push('/afterorder');
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        case REFRESH_ORDER_STATUS:
            try {
                yield initRequest();

                const response = yield call(getOrderDetails,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city,
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload
                );
                
                console.log(response);

                if(response.success) {
                    yield put(refreshOrderStatusSuccess(response.data));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
    default: { throw new Error(`Unexpected action type ${type}`); }
    }
}

export default cartSaga;