import React from 'react'
import { fadeInUp } from 'react-animations'
import { StyleSheet, css } from 'aphrodite';
import { FaCheck, FaCheckCircle, FaChevronDown, FaTimes, FaWindowClose } from 'react-icons/fa'
import { fadeOutDown } from "react-animations";
import './language.css'

const stylesAnimate = StyleSheet.create({
    fadeInUp: {
        animationName: fadeInUp,
        animationDuration: '1s',
        height: '50%',
        width: 'inherit',
        position: 'fixed',
        bottom: 0,
    },
})

const LanguageChooser = ({ LanguageModal, setLanguageModal }) => {
    const listLanguage = [
        { id: 1, type: 'en', name: 'English' },
        { id: 1, type: 'es', name: 'Español' },
        { id: 1, type: 'id', name: 'Bahasa Indonesia' },
        { id: 1, type: 'ms', name: 'Bahasa Melayu' },
        { id: 1, type: 'ph', name: 'Tagalog' },
        { id: 1, type: 'pt', name: 'Português' },
        { id: 1, type: 'th', name: 'ภาษาไทย' },
        { id: 1, type: 'zh', name: '中文' },
    ]
    const language = localStorage.getItem('language')
    const handleLanguage = (type) => {
        localStorage.setItem('language', type)
        window.location.reload()
    }
    window.onclick = (e) => {
        console.log(e.target.className)
        if(e.target.className == 'language-chooser-modal-container') {
            setLanguageModal(false)
        }
    }
    return (
        <>
            {LanguageModal ?
                (<div className="language-chooser-modal-container">
                    <div className={css(stylesAnimate.fadeInUp)}>
                        <div className='container-chooser-language' >
                            <div className='container-close-language'>
                                <FaTimes onClick={() => setLanguageModal(false)} />
                            </div>
                            <div className='container-scroll-card'>
                                <div className='container-card'>
                                    {listLanguage.map((item, index) => {
                                        return (
                                            <div onClick={() => handleLanguage(item.type)} className={item.type === language ? 'card-language-choosed' : 'card-language'} >
                                                <p>{item.name}</p>
                                                {language === item.type && <FaCheckCircle />}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) :
                <></>
            }
        </>
    )
}

export default LanguageChooser