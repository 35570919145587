import React from 'react'

const Edit = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 0 136 136" fill={props.fill}>
            <path d="M78.4,4.8,12.869,70.334A26.692,26.692,0,0,0,5,89.335v7.219a5.375,5.375,0,0,0,5.375,5.375h7.219a26.692,26.692,0,0,0,19-7.869l65.532-65.532a16.8,16.8,0,0,0,0-23.725A17.168,17.168,0,0,0,78.4,4.8ZM94.526,20.928,28.994,86.459a16.233,16.233,0,0,1-11.4,4.719H15.75V89.335a16.232,16.232,0,0,1,4.719-11.4L86,12.4a6.17,6.17,0,0,1,8.525,0,6.036,6.036,0,0,1,0,8.525Z" transform="translate(28.875 0.196)"/>
            <path d="M130.333,50.881a5.667,5.667,0,0,0-5.667,5.667V85H102a17,17,0,0,0-17,17v22.667H28.333a17,17,0,0,1-17-17V28.333a17,17,0,0,1,17-17H79.571A5.667,5.667,0,1,0,79.571,0H28.333A28.367,28.367,0,0,0,0,28.333v79.333A28.367,28.367,0,0,0,28.333,136H92.61a28.152,28.152,0,0,0,20.037-8.3L127.7,112.642A28.152,28.152,0,0,0,136,92.61V56.548A5.667,5.667,0,0,0,130.333,50.881Zm-25.7,68.81a16.858,16.858,0,0,1-8.3,4.533V102A5.667,5.667,0,0,1,102,96.333h22.242a17.091,17.091,0,0,1-4.533,8.3Z"/>
        </svg>
    )
}

export default Edit
