import React from 'react'

const History = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 0 136 135.825">
            <g transform="translate(0.004)">
                <path d="M15.468,6A4.468,4.468,0,0,0,11,10.468V32.81a4.468,4.468,0,0,0,1.309,3.158L25.714,49.375a4.468,4.468,0,1,0,6.319-6.319l-12.1-12.1V10.468A4.468,4.468,0,0,0,15.468,6Z" transform="translate(55.011 33.299)"/>
                <path d="M135.26,57.544A68.154,68.154,0,0,0,20.341,19.4V5.679a5.679,5.679,0,1,0-11.359,0V28.4A11.359,11.359,0,0,0,20.341,39.756H43.059a5.679,5.679,0,0,0,0-11.359H27.435a56.738,56.738,0,1,1-16.1,44.743A5.577,5.577,0,0,0,5.7,68.154,5.719,5.719,0,0,0,.02,74.4,68.154,68.154,0,1,0,135.237,57.539Z" transform="translate(0)"/>
            </g>
        </svg>
    )
}

export default History
