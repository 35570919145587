import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { AfterOrderButton, BackTitleTopNav } from '../components/common'
// import Countdown from 'react-countdown';
import { refreshOrderStatus } from '../../application/actions/cartActions';

import { AfterOrder } from '../'
import { t } from '../../miscellaneous/language';
const AfterOrderHistoryContainer = ({match}) => {
    const { orderId } = match.params;
    
    const dispatch = useDispatch();
    
    const store = useSelector((state) => ({
        cart: state.cart,
        auth: state.auth,
        history: state.history
    }), shallowEqual);
    
    const [order_details, setOrderDetails] = React.useState(null);
    const [staticStatus, setStaticStatus] = React.useState(null);
    const [timer, setTimer] = React.useState(2500);

    const refreshStatus = async () => {
        await setOrderDetails(null)
        await dispatch(refreshOrderStatus(orderId));
        console.log(store.cart?.orders_response)
        if(store.cart?.orders_response?.id === orderId) {
            await setStaticStatus(store.cart?.orders_response?.status);
            await setOrderDetails(store.cart?.orders_response);
            await setTimer(3500)
        }
    }

    const updateStatus = (status) => {
        setStaticStatus(status);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshStatus();
        }, timer);

        if(order_details !== null) {
            if(order_details?.id !== orderId) {
                setOrderDetails(null)
                refreshStatus();
            }
        }

        return () => clearInterval(interval);
    }, [store.cart, order_details, staticStatus])

    return (
        <>
           <Helmet>
                <title>{t.helmet.title.HistoryDetail}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={t.helmet.desc.HistoryDetail} />
            </Helmet>
            <BackTitleTopNav hideButton={true} title={order_details?.code ? order_details?.code : 'OD-000000000'} orderReg={order_details?.reg} />
            <AfterOrder order_data={staticStatus} updateStaticStatus={updateStatus}/>
            {/* {staticStatus == 0 ? 
            <div style={{position: 'absolute', display: 'flex', width: '100%', bottom: 120, justifyContent: 'center', alignItems: 'center'}}>
                <Countdown date={Date.parse(order_details?.created_at ? order_details?.created_at : '1970-01-01T00:00:00.000000Z') - (420 * 60000) + 120000} />
            </div>
            :
            <></>} */}
            <AfterOrderButton status={staticStatus} refreshStatus={refreshStatus}/>
        </>
    )
}

export default AfterOrderHistoryContainer
