import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { BottomSpace, ReceiptTopNav } from '../components/common'

import { Receipt } from '../'
import { usePublicLink } from '../../application/hooks'
import {t} from '../../miscellaneous/language'
import { confirmAlert } from 'react-confirm-alert';

const ReceiptContainer = ({ match }) => {
    const { orderId } = match.params;
    const { orderData } = usePublicLink(orderId);

    console.log(orderData?.data.merchant.partner_level == 0)

    const checkMerchhantPartner = () => {
        
            if(orderData?.data.merchant.partner_level == 0) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="alert">
                                <h1>{t.alert.nonPartner}</h1>
                                <p>{t.alert.noteNonPartnerReceipt}</p>
                                <div className="alert-button-container">
                                    <button
                                        className="yes-button-receipt" 
                                        onClick={onClose}
                                    >
                                        {t.btn.yes}
                                    </button>
                                    
                                </div>
                            </div>
                        )
                    }
                })
            
        }
    }

    useEffect(() => {
        console.log('render')
        checkMerchhantPartner()

    }, [orderData])

    return (
        <div>
            <Helmet>
                <title>{"Foods&Goods - Struk Pemesanan"}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={"Struk Pemesanan"} />
            </Helmet>
            <ReceiptTopNav />
            <Receipt orderData={orderData}/>
            <BottomSpace />
        </div>
    )
}

export default ReceiptContainer
