const En = {
    btn: {
        saveName: 'Save Name' ,
        yes: 'Yes',
        no: 'No',
        add: 'Add',
        viewCart: 'View my cart',
        alertOk: 'Yes, i understand',
        confirmOrder:'confirm your order',
        shopping: `Let's shopping!`,
        status: {
            orderStatus0:'Refresh order status',
            orderStatus1:'Check delivery status',
            orderStatus9:'Order from other merchants'
        }
    },
    text: {
        saveName: 'Hello, please enter yourname before continue...',
        item: 'items',
        empty: 'Sold Out',
        orderList: 'ORDER LIST',
        billDetail: 'BILL DETAIL',
        totalPrice: 'Price Total',
        totalDisc: 'Discount Total',
        platformFee: 'Platform Fee',
        deliveryFee: 'Delivery Fee',
        toBePaid: 'To Be Paid',
        emptyCart: 'Your shopping cart is still empty :(',
        orderPlaced: {
            title: 'order successfully made',
            desc: 'Waiting for the merchant to confirm your order'
        },
        merchantCancel: {
            title: 'your order is cancelled',
            desc: 'Merchant cancels your order'
        },
        merchantconfirm: {
            title: 'your order is confirmed',
            desc: 'Merchant confirms your order'
        },
        itemPickedUp: {
            title: 'your order is being shipped',
            desc: 'Thank you for using the Maxim service'
        },
        completeStatus: {
            title: 'your order is complete',
            desc: 'Thank you for using the Maxim service'
        },
        userCancel: {
            title: 'your order is cancelled',
            desc: 'User cancels your order'
        },
        buyerName: 'Buyer Name',
        receiveAt: 'Receive At',
        status: 'Status',
        paymentType: 'Payment Type',
        cash: 'Cash',
        note: 'Note: ',
        noNote: 'No note',
        noteMerchant: 'Note for Merchant'
    },
    label:{
        user_name: 'Your Name: '
    },
    placeholder: {
        name: 'Input your name',
        searchHome: 'Search merchant or item ...',
        searchCategory: "Search Merchant",
        noteItemDetail: 'write here for special notes',
        searchItem: 'Search Item ...',
        noteMerchant: "Write your comment or suggestion for Merchant" 
    },
    alert:{
        orderCancel: 'This order is cancelled',
        nonPartner: 'Non Partner',
        noteNonPartner: 'This merchant is not an official Maxim partner, product availability and price accuracy may change and differ from actual conditions.. Continue?',
        cart: 'Cart',
        cartEmpty: 'There is only one quantity left for this item/menu in your cart, decreasing quantity means removing items from your cart.. Continue?',
        alert: 'Alert',
        noteProcessOrder: 'The server is processing your request, please wait... If the process takes too long, please restart your application or check your internet connection..',
        confirmOrder: 'Confirm Order',
        noteConfirmOrder: 'Are you sure you want to forward your order? Please check again before confirming the order',
        noteCountDeliveryPrice: 'An error occurred while calculating the shipping cost, please close the application and reopen it to continue..',
        noteDeliveryPrice: 'An error occurred while retrieving your location for postage calculations, please close the application and reopen it and make sure your gps is on to continue..',
        noteNonPartnerReceipt: 'This merchant is not an official Maxim partner, item availability and prices listed may vary. Please make a payment according to the price stated on the receipt.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Start',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Category Merchant",
            History: 'Foods&Goods - History',
            DetailCart: "Foods&Goods - Cart Detail",
            afterOrder: 'Foods&Goods - After Order',
            HistoryDetail: 'Foods&Goods - History Detail',
        },
        desc: {
            start:'Start',
            Home: "Foods&Goods",
            Category: "Merchant list by category",
            History: 'List of transactions you have done',
            DetailCart: "Item details in your cart",
            afterOrder: 'After Order',
            HistoryDetail: 'History Detail',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'HIstory'
    },
    loading: 'Loading ...',
    data: {
        noDataMerchant: 'There is no merchant found', 
        DataMerchant: 'Merchant found', 
    },
    bottomNav: {
        Home: 'Home',
        Explore: 'Explore',
        Promo: 'Promo',
        History: 'History',
    },
    status: {
        Open: 'Open',
        Close: 'Close',
        case0: 'Awaiting Store approval',
        case1: 'Order approved by the store',
        case2: 'Order brought by Driver Maxim',
        case9: 'Order canceled',
        caseNotFound: 'Order Status Not Found',
        orderStatus0 : 'Waiting',
        orderStatus1 : 'Approved by Merchant',
        orderStatus2 : 'Picked up by Driver',
        orderStatus11 : 'Completed',
        orderStatus99 : 'Cancelled',
        orderStatusNotFound : 'Status not found'
    }
}

export default En