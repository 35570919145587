import axios from 'axios';

const baseURL = 'https://brmarket-api.taximaxim.com/api/v1/name';

export const setUserdataAPI = async (userID, lat, lon, city, host, name, addressName) => {
    const res = await axios.post(baseURL, {
        userID,
        lat,
        lon,
        city,
        host,
        name,
        addressName
    });

    return res.data;
}