import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '@material-ui/core';

import { shallowEqual, useSelector } from 'react-redux';

import * as ROUTE from '../../../../miscellaneous/constants';

import {
    Home, 
    // Explore, 
    // Promo, 
    History
} from '../../../../assets/'

import './BottomNav.css'
import { t } from '../../../../miscellaneous/language';

const BottomNav = () => {
    const location = useLocation();

    const store = useSelector((state) => ({
        auth: state.auth
    }), shallowEqual);

    return (
        // <div>
        //     <nav className="bottomnav-container">
        //         <ul className="bottomnav-ul">
        //             <li className="bottomnav-li">
        //                 <Link className={location.pathname === ROUTE.ROUTE_HOME ? 
        //                     'bottomnav-a active' : 'bottomnav-a'} 
        //                     to={ROUTE.ROUTE_HOME}
        //                 >
        //                     <center><GoHome style={iconsStyle} /></center>
        //                     Home
        //                 </Link>
        //             </li>
        //             <li className="bottomnav-li">
        //                 <Link className={location.pathname === ROUTE.ROUTE_EXPLORE ? 
        //                     'bottomnav-a active' : 'bottomnav-a'} 
        //                     to={ROUTE.ROUTE_EXPLORE}
        //                 >
        //                     <center><GoSearch style={iconsStyle} /></center>
        //                     Explore
        //                 </Link>
        //             </li>
        //             <li className="bottomnav-li">
        //                 <Link className={location.pathname === ROUTE.ROUTE_PROMO ? 
        //                     'bottomnav-a active' : 'bottomnav-a'} 
        //                     to={ROUTE.ROUTE_PROMO}
        //                 >
        //                     <center><ImPriceTags style={iconsStyle} /></center>
        //                     Promo
        //                 </Link>
        //             </li>
        //             <li className="bottomnav-li">
        //                 <Link className={location.pathname === ROUTE.ROUTE_HISTORY ? 
        //                     'bottomnav-a active' : 'bottomnav-a'} 
        //                     to={ROUTE.ROUTE_HISTORY}
        //                 >
        //                     <center><GrHistory style={iconsStyle} /></center>
        //                     History
        //                 </Link>
        //             </li>
        //         </ul>
        //     </nav>
        // </div>
        <div>
            {/* <nav className="bottomnav-container">
                <ul className="bottomnav-ul">
                    <li className="bottomnav-li">
                        <Link className={location.pathname === ROUTE.ROUTE_HOME ? 
                            'bottomnav-a active' : 'bottomnav-a'} 
                            to={ROUTE.ROUTE_HOME}
                        >
                            <center><Home height="18" /></center>
                            Home
                        </Link>
                    </li>
                    <li className="bottomnav-li">
                        <Link className={location.pathname === ROUTE.ROUTE_EXPLORE ? 
                            'bottomnav-a active' : 'bottomnav-a'} 
                            to={ROUTE.ROUTE_EXPLORE}
                        >
                            <center><Explore /></center>
                            Explore
                        </Link>
                    </li>
                    <li className="bottomnav-li">
                        <Link className={location.pathname === ROUTE.ROUTE_PROMO ? 
                            'bottomnav-a active' : 'bottomnav-a'} 
                            to={ROUTE.ROUTE_PROMO}
                        >
                            <center><Promo /></center>
                            Promo
                        </Link>
                    </li>
                    <li className="bottomnav-li">
                        <Link className={location.pathname === ROUTE.ROUTE_HISTORY ? 
                            'bottomnav-a active' : 'bottomnav-a'} 
                            to={ROUTE.ROUTE_HISTORY}
                        >
                            <center><History /></center>
                            History
                        </Link>
                    </li>
                </ul>
            </nav> */}
            <div className="bottomnav-wrapper">
                <div className="bottomnav-container">
                    <Link className={location.pathname === ROUTE.ROUTE_HOME ? 
                        'bottomnav-link active' : 'bottomnav-link'} 
                        to={ROUTE.ROUTE_HOME}
                    >
                        <Home id="bottomnav-icon" />
                        <span className="bottomnav-text">{t.bottomNav.Home}</span>
                    </Link>
                    <div style={{width:1, backgroundColor:'#636e72', height:'50%'}}></div>
                    {/* <Link className={location.pathname === ROUTE.ROUTE_EXPLORE ? 
                        'bottomnav-link active' : 'bottomnav-link'} 
                        to={ROUTE.ROUTE_EXPLORE}
                    >
                        <Explore id="bottomnav-icon" />
                        <span className="bottomnav-text">{t.bottomNav.Eksplor}</span>
                    </Link>
                    <Link className={location.pathname === ROUTE.ROUTE_PROMO ? 
                        'bottomnav-link active' : 'bottomnav-link'} 
                        to={ROUTE.ROUTE_PROMO}
                    >
                        <Promo id="bottomnav-icon" />
                        <span className="bottomnav-text">{t.bottomNav.Promo}</span>
                    </Link> */}
                    <Link className={location.pathname === ROUTE.ROUTE_HISTORY ? 
                        'bottomnav-link active' : 'bottomnav-link'} 
                        to={ROUTE.ROUTE_HISTORY}
                    >
                        {store?.auth?.userData?.running_order ? 
                            <Badge color="secondary" variant="dot">
                                <History id="bottomnav-icon" />
                            </Badge>
                        : 
                            <History id="bottomnav-icon" />
                        }
                        <span className="bottomnav-text">{t.bottomNav.History}</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default BottomNav
