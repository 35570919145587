const Ms = {
    btn: {
        saveName: 'Simpan Nama' ,
        yes: 'ya',
        no: 'Tidak',
        add: 'Tambah',
        viewCart: 'Lihat troli saya',
        alertOk: 'Ya saya faham',
        confirmOrder:'mengesahkan pesanan anda',
        shopping: `Jom shopping!`,
        status: {
            orderStatus0:'Muat semula status pesanan',
            orderStatus1:'Semak status penghantaran',
            orderStatus9:'Tempahan dari peniaga lain'
        }
    },
    text: {
        saveName: 'Helo, sila masukkan nama anda sebelum meneruskan...',
        item: 'items',
        empty: 'Habis Dijual',
        orderList: 'SENARAI PESANAN',
        billDetail: 'BUTIR BIL',
        totalPrice: 'Jumlah Harga',
        totalDisc: 'Jumlah Diskaun',
        platformFee: 'Yuran Platform',
        deliveryFee: 'Yuran Penghantaran',
        toBePaid: 'Untuk Dibayar',
        emptyCart: 'Keranjang belanja anda masih kosong :(',
        orderPlaced: {
            title: 'pesanan berjaya dibuat',
            desc: 'Menunggu saudagar mengesahkan pesanan anda'
        },
        merchantCancel: {
            title: 'Pesanan anda dibatalkan',
            desc: 'Pedagang membatalkan pesanan anda'
        },
        merchantconfirm: {
            title: 'Pesanan anda disahkan',
            desc: 'Pedagang mengesahkan pesanan anda'
        },
        itemPickedUp: {
            title: 'Pesanan anda dihantar',
            desc: 'Terima kasih kerana menggunakan perkhidmatan Maxim'
        },
        completeStatus: {
            title: 'Pesanan anda selesai',
            desc: 'Terima kasih kerana menggunakan perkhidmatan Maxim'
        },
        userCancel: {
            title: 'Pesanan anda dibatalkan',
            desc: 'Pengguna membatalkan pesanan anda'
        },
        buyerName: 'Nama pembeli',
        receiveAt: 'Terima di',
        status: 'Status',
        paymentType: 'Jenis pembayaran',
        cash: 'Tunai',
        note: 'Catatan: ',
        noNote: 'Tiada nota',
        noteMerchant: 'Nota untuk Pedagang'
    },
    label:{
        user_name: 'Nama awak: '
    },
    placeholder: {
        name: 'Masukkan nama anda',
        searchHome: 'Cari peniaga atau item ...',
        searchCategory: "Cari Pedagang",
        noteItemDetail: 'Tulis di sini untuk nota khas',
        searchItem: 'Item cari ...',
        noteMerchant: "Tulis komen atau cadangan anda untuk peniaga" 
    },
    alert:{
        orderCancel: 'Pesanan ini dibatalkan',
        nonPartner: 'Bukan rakan kongsi',
        noteNonPartner: 'Pedagang ini bukan rakan kongsi maksim rasmi, ketersediaan produk dan ketepatan harga boleh berubah dan berbeza dari keadaan sebenar .. Teruskan?',
        cart: 'Kereta',
        cartEmpty: 'Terdapat hanya satu kuantiti yang tersisa untuk item/menu ini di dalam troli anda, mengurangkan kuantiti bermakna mengeluarkan item dari kereta anda .. Teruskan?',
        alert: 'Amaran',
        noteProcessOrder: 'Pelayan memproses permintaan anda, sila tunggu ... jika proses mengambil masa terlalu lama, sila mulakan semula permohonan anda atau periksa sambungan internet anda ..',
        confirmOrder: 'Mengesahkan pesanan',
        noteConfirmOrder: 'Adakah anda pasti mahu meneruskan pesanan anda? Sila periksa lagi sebelum mengesahkan pesanan',
        noteCountDeliveryPrice: 'Ralat berlaku semasa mengira kos penghantaran, sila tutup permohonan dan buka semula untuk meneruskan ..',
        noteDeliveryPrice: 'Ralat berlaku semasa mengambil lokasi anda untuk pengiraan pos, sila tutup permohonan dan buka semula dan pastikan GPS anda terus meneruskan ..',
        noteNonPartnerReceipt: 'Pedagang ini bukan rakan kongsi maksim rasmi, ketersediaan item dan harga yang disenaraikan mungkin berbeza -beza. Sila buat pembayaran mengikut harga yang dinyatakan pada resit.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Mula',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Pedagang Kategori",
            History: 'Foods&Goods - Sejarah',
            DetailCart: "Foods&Goods - Perincian kereta",
            afterOrder: 'Foods&Goods - Selepas pesanan',
            HistoryDetail: 'Foods&Goods - Perincian sejarah',
        },
        desc: {
            start:'Mula',
            Home: "Foods&Goods",
            Category: "Senarai saudagar mengikut kategori",
            History: 'Senarai urus niaga yang telah anda lakukan',
            DetailCart: "Maklumat item di troli anda",
            afterOrder: 'Selepas pesanan',
            HistoryDetail: 'Perincian sejarah',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'Sejarah'
    },
    loading: 'Memuatkan ...',
    data: {
        noDataMerchant: 'Tidak ada saudagar yang dijumpai', 
        DataMerchant: 'Pedagang dijumpai', 
    },
    bottomNav: {
        Home: 'Rumah',
        Explore: 'Meneroka',
        Promo: 'Promo',
        History: 'Sejarah',
    },
    status: {
        Open: 'Buka',
        Close: 'Tutup',
        case0: 'Menunggu kelulusan kedai',
        case1: 'Pesanan yang diluluskan oleh kedai',
        case2: 'Pesanan yang dibawa oleh pemacu maksim',
        case9: 'Pesanan dibatalkan',
        caseNotFound: 'Status pesanan tidak dijumpai',
        orderStatus0 : 'Menunggu',
        orderStatus1 : 'Diluluskan oleh Pedagang',
        orderStatus2 : 'Dijemput oleh pemandu',
        orderStatus11 : 'Selesai',
        orderStatus99 : 'Dibatalkan',
        orderStatusNotFound : 'Status tidak dijumpai'
    }
}

export default Ms