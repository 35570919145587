import React from 'react';
import { Switch, Route, Router as AppRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import * as view from '../../views';

import {
    ROUTE_START,
    ROUTE_HOME,
    ROUTE_MERCHANT_DETAIL,
    ROUTE_CATEGORY,

    ROUTE_CART_DETAIL,
    ROUTE_EXPLORE,
    // ROUTE_PROMO, DON'T DELETE
    

    // ROUTE_ALL_PROMO, DON'T DELETE

    ROUTE_PROMO_DETAIL, 
    // ROUTE_ALL_ITEM_ON_DISCOUNT, NO ROUTE ON CONSTANT
    // ROUTE_OPENING_PROMO, DON'T DELETE

    ROUTE_HISTORY,

    ROUTE_AFTER_ORDER,
    ROUTE_HISTORY_DETAILS,

    ROUTE_RECEIPT,
} from '../../miscellaneous/constants';

export const history = createBrowserHistory();

const Router = () => (
    <>
        <AppRouter history={history}>
            <Switch>
                <Route exact path={ROUTE_START} component={view.StartContainer} />
                <Route exact path={ROUTE_HOME} component={view.HomeContainer} />

                <Route exact path={ROUTE_CART_DETAIL} component={view.CartDetailContainer} />
                <Route exact path={ROUTE_CATEGORY} component={view.CategoryContainer} />


                <Route exact path={ROUTE_EXPLORE} component={view.ExploreContainer} />
                {/* <Route exact path={ROUTE_PROMO} component={view.PromoDashboardContainer} />  DON'T DELETE */} 
                <Route exact path={ROUTE_PROMO_DETAIL} component={view.PromoDetailContainer}  /> {/* DETAIL PROMO */}
                
                {/* <Route exact path={ROUTE_ALL_PROMO} component={view.PromoAllContainer} />  DON'T DELETE */}
                {/* <Route exact path={ROUTE_OPENING_PROMO} component={view.OpeningPromoContainer} /> DON'T DELETE */}

                {/* <Route exact path={ROUTE_ALL_ITEM_ON_DISCOUNT} component={view.AllItemOnDiscountContainer} NO ROUTE ON CONTANT /> */}
                
                <Route exact path={ROUTE_HISTORY} component={view.HistoryContainer} />
                <Route exact path={ROUTE_MERCHANT_DETAIL} component={view.MerchantDetailContainer} />

                <Route exact path={ROUTE_AFTER_ORDER} component={view.AfterOrderContainer} />
                <Route exact path={ROUTE_HISTORY_DETAILS} component={view.AfterOrderHistoryContainer} />
                <Route exact path={ROUTE_RECEIPT} component={view.ReceiptContainer} />
            </Switch>
        </AppRouter>
    </>
)

export default Router;
