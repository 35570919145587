import React from 'react'
import PropType from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';

import CategoryItem from '../Category/CategoryItem'

import 'swiper/swiper.min.css';
import '../Category/Category.css'
import { t } from '../../miscellaneous/language';
// import OtherCategoryItem from '../Category/otherCategoryItem';

const CategoryListContainer = ({ items }) => {
    return (
        <div className="category-container">
            <Swiper
                breakpoints={{
                    500: {
                        width: 500,
                        slidesPerView: 5

                    },
                    425: {
                        width: 425,
                        slidesPerView: 4
                    },
                    375: {
                        width: 375,
                        slidesPerView: 4

                    },
                    320: {
                        width: 320,
                        slidesPerView: 3

                    },
                    280: {
                        width: 280,
                        slidesPerView: 2
                    }
                }}
            >
                
                {!items ? <span>{t.loading}</span> : items?.map((catItem, index) => (
                    <SwiperSlide key={index}>
                        <CategoryItem
                            item={catItem}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

CategoryListContainer.propTypes = {
    items: PropType.array
}

export default CategoryListContainer
