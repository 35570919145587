import React from 'react'
import PropType from 'prop-types';

import {
    Star,
    District,
    PriceLabel,
    MerchantImage
} from '../../assets/'

import './Merchant.css';

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';
import { t } from '../../miscellaneous/language';

const FilterNearestMerchantItem = ({ merchants }) => {
    const history = useHistory();
    return (
        <>
            {merchants.map((merchant, index) => (
                <div key={index} className="merchant-list-card " onClick={(e) => { merchant.is_closed === 1 ? alert('Merchant sedang tutup!') : history.push(`/merchant/${merchant.id}`) }}>
                    {merchant.is_closed === 0 ? 
                        <div className="img-container">
                            <img src={merchant.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                            {/* <div className="merchant-list-img-status">status</div> */}
                        </div>
                        :
                        <div className="merchant-list-img" style={{backgroundImage:"url(" + merchant.image + ")", marginRight:10}} >
                           <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}>{merchant.is_closed === 0 ? t.status.Open : t.status.Close}</p>
                        </div>
                    }
                    
                    <div className="merchant-list-description-container">
                        <span className="merchant-list-name">{merchant.name}</span>
                        <span className="merchant-list-desc">{merchant.desc}</span>
                        <div className="border"></div>
                        <div className="merchant-list-detail-container">
                            <div className="merchant-list-detail"><Star id="merchant-icon-info" fill="#FF6000" /> <span>{merchant.avg_rating}</span></div>
                            <div className="merchant-list-detail"><District id="merchant-icon-info" /> <span>{merchant.distance?.toFixed(2)} KM</span></div>
                            <div className="merchant-list-detail"><PriceLabel id="merchant-icon-info" /> <span>{formatCurrencyKStyle(merchant.min)} - {formatCurrencyKStyle(merchant.max)}</span></div>
                            {/* <div className="merchant-list-detail"><span className="merchant-list-detail-halal">NON HALAL</span></div> */}
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? <HalalLogo id="halal-logo" /> : ""}</span></div> : ''} */}
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? "halal" : "non-halal"}</span></div> : ''} */}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

FilterNearestMerchantItem.propTypes = {
    merchants: PropType.array.isRequired
}

export default FilterNearestMerchantItem;
