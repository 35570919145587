import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import PromoTag from "../components/common/Badge/PromoBadge";
import { GoPlus, GoDash } from "react-icons/go";

import {
  removeFromCart,
  setNoteItems,
  addQtyItem,
  removeQtyItem,
} from "../../application/actions/cartActions";

import { useCart } from "../../application/hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { formatCurrency } from "../../miscellaneous/helpers/utils";

import "./Merchant.css";
import { ItemImage } from "../../assets";
import { t } from "../../miscellaneous/language";

const ItemDetailMainContent = ({ item, itemCat }) => {
  const dispatch = useDispatch();

  const [descText, setDescText] = React.useState(null);

  const store = useSelector(
    (state) => ({
      merchants: state.merchants.details,
    }),
    shallowEqual
  );

  const { addToCart, checkItemOnCart, getItemNote, itemQtyCounter } = useCart(
    item.id
  );

  const onItemAddToCart = (id) => {
    if (itemQtyCounter(id) >= 1) {
      dispatch(addQtyItem(id));
    } else {
      addToCart({ ...item, qty: 1, orderDesc: descText }, store.merchants);
    }
  };

  const onItemRemoveFromCart = (id) => {
    if (itemQtyCounter(id) > 1) {
      dispatch(removeQtyItem(id));
    } else {
      // confirmAlert({
      //     title: 'Keranjang',
      //     message: "Barang/menu tersisa satu buah dalam keranjang, mengurangi kuantitas akan menghapus barang/menu dari keranjang.. Lanjutkan?",
      //     buttons: [
      //       {
      //         label: 'Ya',
      //         onClick: () => {
      //             dispatch(removeFromCart(id));
      //         }
      //       },
      //       {
      //         label: 'Tidak'
      //       }
      //     ]
      // });
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert">
              <h1>{t.alert.cart}</h1>
              <p>
                {t.alert.cartEmpty}
              </p>
              <div className="alert-button-container">
                <button
                  class="yes-button"
                  onClick={() => {
                    dispatch(removeFromCart(id));
                    onClose();
                  }}>
                  {t.btn.yes}
                </button>
                <button class="no-button" onClick={onClose}>
                  {t.btn.no}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  const onDescriptionFilled = (id, desc) => {
    setDescText(desc);
    dispatch(setNoteItems(id, desc));
  };

  return (
    <div className="item-detail-main-content">
      <div className="item-detail-img-container">
        <img
          src={item.image}
          onError={(event) => (event.target.src = ItemImage)}
          alt=""
        />
      </div>
      <div className="item-detail-desc">
        <span className="item-detail-name">{item.name}</span>
        {/* <span className="item-detail-category">{(itemCat === 'None' ? 'Discounted Product' : itemCat)}</span> */}
        {item.discount === 1 ? (
          <span className="item-detail-discounted">
            {formatCurrency(item.total)}
          </span>
        ) : (
          ""
        )}
        <span className="item-detail-price">
          {item.discount === 1 ? (
            <>
              <s>{formatCurrency(item.price)}</s>
              <PromoTag />
            </>
          ) : (
            formatCurrency(item.price)
          )}
        </span>
        <span className="item-detail-description">{item.desc}</span>
        <div className="item-detail-counter">
          {item.is_empty !== 1 ? (
            ""
          ) : checkItemOnCart(item.id) ? (
            <>
              <button onClick={() => onItemRemoveFromCart(item.id)}>
                <GoDash className="button-counter-icon" />
              </button>
              <span className="menu-counted">
                {itemQtyCounter(item.id) === undefined
                  ? 0
                  : itemQtyCounter(item.id)}
              </span>
              <button onClick={() => onItemAddToCart(item.id)}>
                <GoPlus className="button-counter-icon" />
              </button>
            </>
          ) : (
            <button onClick={onItemAddToCart}>{t.btn.add}</button>
          )}
        </div>
      </div>
      <div className="item-detail-notes">
        {item.is_empty !== 1 ? (
          ""
        ) : (
          <input
            type="text"
            onChange={(e) => onDescriptionFilled(item.id, e.target.value)}
            placeholder={t.placeholder.noteItemDetail}
            value={getItemNote(item.id)}
          />
        )}
      </div>
    </div>
  );
};

export default ItemDetailMainContent;
