import React, { useEffect } from 'react'
import { Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import './TopNav.css'

import {
    Cart
} from '../../../../assets/'

import { FaGlobe } from 'react-icons/fa'
import { t } from '../../../../miscellaneous/language';

const HomePageTopNav = ({setLanguageModal}) => {
    const history = useHistory();

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const countSumOfQty = () => {
        let total = 0;
        for (var i in cart.items) {
            total += cart.items[i].qty;
        }   
        return total;     
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="homepage-topnav">
                <span className="homepage-topnav-title">{t.topNav.title}</span>
                <div style={{ display:'flex' }}>
                    <div style={{ marginRight:20 }}>
                            <FaGlobe style={{ fontSize:25 }} onClick={(e) => setLanguageModal(true)} />
                    </div>
                    <div className="homepage-cart-container">
                        <Badge badgeContent={countSumOfQty()} onClick={(e) => {history.push("/cart/detail")}} color="secondary">
                            <Cart id="cart-small-icon" />
                        </Badge>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageTopNav
