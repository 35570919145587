import axios from "axios";

const baseURL = "https://brmarket-api.taximaxim.com/api/v1/order";

export const sendOrderAPI = async (
  userID,
  lat,
  lon,
  city,
  host,
  name,
  addressName,
  total_item,
  total_price,
  total_discount,
  delivery_price,
  total,
  notes,
  merchants_id,
  order_items,
  platform_fee
) => {
  const res = await axios.post(baseURL, {
    userID,
    lat,
    lon,
    city,
    host,
    name,
    addressName,
    total_item,
    total_price,
    total_discount,
    delivery_price,
    total,
    notes,
    merchants_id,
    order_items,
    platform_fee: platform_fee,
    origin: "ph",
  });

  return res.data;
};

export const getOrderDetails = async (
  userID,
  lat,
  lon,
  city,
  host,
  name,
  addressName,
  orderId
) => {
  const res = await axios.get(baseURL + "/" + orderId, {
    params: {
      userID,
      lat,
      lon,
      city,
      host,
      addressName,
      name,
      origin: "en",
    },
  });

  return res.data;
};
