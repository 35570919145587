import axios from 'axios';

const baseURL = 'https://brmarket-api.taximaxim.com/api/v1/search';

export const searchMerchantAPI = async (userID, lat, lon, city, host, name, addressName, param, page) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
            param
        }
    });

    return res.data;
}