import {
  GET_MERCHANTS,
  GET_MERCHANT_DETAILS,
  SEARCH_MERCHANT,
  FILTER_MERCHANT_NEAR_ME,
  FILTER_MERCHANT_DISTRICT,
  GET_CATEGORY_MERCHANTS,
  SEARCH_MERCHANT_CATEGORY,
  FILTER_MERCHANT_CATEGORY_NEAR_ME,
  FILTER_MERCHANT_CATEGORY_DISTRICT,
} from "../../../miscellaneous/constants";
import { select, call, put } from "redux-saga/effects";

import { actionMessage } from "../../../miscellaneous/helpers/utils";

import { setLoading, setRequestStatus } from "../../actions/miscActions";
import {
  getMerchantsSuccess,
  getMerchantDetailsSuccess,
  searchMerchantSuccess,
  clearSearchState,
  filterNearestMerchantSuccess,
  filterDistrictMerchantSuccess,
  clearFilterDistrictState,
  setTypeState,
  getCategoryMerchantsSuccess,
  searchMerchantCategorySuccess,
  filterNearestMerchantCategorySuccess,
  filterDistrictMerchantCategorySuccess,
  clearFilterNearMeCategoryState,
  clearFilterDistrictCategoryState,
  clearFilterNearMeState,
  clearSearchCategoryState,
  setIsLoadMore,
} from "../../actions/merchantActions";

import {
  getMerchantsAPI,
  getMerchantDetailsAPI,
  getMerchantsCategoryAPI,
} from "../../../infrastructure/services/api/Merchant";

import { history } from "../../routers/Router";

export const getUserData = (state) => state.auth.userData;
export const getIsLoadMore = (state) => state.merchants.isLoadMore;

function* initRequest() {
  yield put(setLoading(true));
  yield put(setRequestStatus(null));
}

function* handleError(e) {
  yield put(setLoading(false));
  yield put(setRequestStatus(e?.message || "Failed to fetch merchants"));
  console.log("ERROR: ", e);
}

function* handleAction(location, message, status) {
  if (location) yield call(history.push, location);
  yield call(actionMessage, message, status);
}

function* merchantSaga({ type, payload }) {
  const userData = yield select(getUserData);
  const loadMore = yield select(getIsLoadMore);
  const today = new Date()
  const day = today.getDay()
  const time = `${today.getHours() < 10 ? 0 : ''}${today.getHours()}:${today.getMinutes() < 10 ? 0 : ''}${today.getMinutes()}:00`
          
  today.getDay()

  switch (type) {
    case GET_MERCHANTS:
      try {
        yield initRequest();

        const response = yield call(
          getMerchantsAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "DEFAULT",
          payload.pageSize
        );

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            getMerchantsSuccess({
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case GET_MERCHANT_DETAILS:
      try {
        yield initRequest();

        const response = yield call(getMerchantDetailsAPI, payload.merchantId);
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            getMerchantDetailsSuccess({
              details: response.result,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case GET_CATEGORY_MERCHANTS:
      try {
        yield initRequest();

        const response = yield call(
          getMerchantsCategoryAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "DEFAULT",
          payload.pageSize,
          payload.categoryId
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            getCategoryMerchantsSuccess({
              categoryId: payload.categoryId,
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case SEARCH_MERCHANT_CATEGORY:
      try {
        // yield put(setTypeState("SEARCH"));
        if (!loadMore) {
          yield initRequest();
          yield put(clearSearchCategoryState());
        }
        else{
          yield put(setRequestStatus(null));
        }

        const response = yield call(
          getMerchantsCategoryAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "SEARCH",
          payload.pageSize,
          payload.categoryId,
          payload.searchKey
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(clearSearchCategoryState());
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            searchMerchantCategorySuccess({
              categoryId: payload.categoryId,
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }
        yield put(setLoading(false));
        yield put(setTypeState("SEARCH"));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case FILTER_MERCHANT_CATEGORY_NEAR_ME:
      try {
        if (!loadMore) {
          yield initRequest();
          yield put(clearFilterNearMeCategoryState());
        }
        else{
          yield put(setRequestStatus(null));
        }

        const response = yield call(
          getMerchantsCategoryAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "FILTER_NEAR_ME",
          payload.pageSize,
          payload.categoryId,
          day,
          time,
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            filterNearestMerchantCategorySuccess({
              categoryId: payload.categoryId,
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case FILTER_MERCHANT_CATEGORY_DISTRICT:
      try {
        yield initRequest();
        yield put(setTypeState("FILTER_DISTRICT"));
        if (!loadMore) {
          yield put(clearFilterDistrictCategoryState());
        }

        const response = yield call(
          getMerchantsCategoryAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "FILTER_DISTRICT",
          payload.pageSize,
          payload.categoryId,
          payload.districtId
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            filterDistrictMerchantCategorySuccess({
              categoryId: payload.categoryId,
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case SEARCH_MERCHANT:
      try {
        yield initRequest();
        yield put(setTypeState("SEARCH"));
        if (!loadMore) {
          yield put(clearSearchState());
        }

        const response = yield call(
          getMerchantsAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "SEARCH",
          payload.pageSize,
          payload.searchKey
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(clearSearchState());
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            searchMerchantSuccess({
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case FILTER_MERCHANT_NEAR_ME:
      try {
        yield initRequest();
        if (!loadMore) {
          yield put(clearFilterNearMeState());
        }

        const response = yield call(
          getMerchantsAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "FILTER_NEAR_ME",
          payload.pageSize,
          day,
          time,
        );
        console.log(response);

        if (response.result.length === 0) {
          yield put(setLoading(false));
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            filterNearestMerchantSuccess({
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    case FILTER_MERCHANT_DISTRICT:
      try {
        yield initRequest();
        yield put(setTypeState("FILTER_DISTRICT"));
        if (!loadMore) {
          yield put(clearFilterDistrictState());
        }

        const response = yield call(
          getMerchantsAPI,
          userData.userID,
          userData.lat,
          userData.lon,
          userData.city,
          userData.host,
          userData.name,
          userData.addressName,
          "FILTER_DISTRICT",
          payload.pageSize,
          payload.districtId
        );
        console.log(response);

        if (response.result.length === 0) {
          handleError("No merchants found.");
          yield put(setIsLoadMore(false));
        } else {
          yield put(
            filterDistrictMerchantSuccess({
              merchants: response.result,
              total: response.total,
            })
          );
          yield put(setRequestStatus(""));
        }

        yield put(setLoading(false));
      } catch (error) {
        console.log(error);
        yield handleError(error);
        yield handleAction(undefined, "Something went wrong", "error");
      }
      break;
    default: {
      throw new Error(`Unexpected action type ${type}`);
    }
  }
}

export default merchantSaga;
