import React from "react";
import PropType from "prop-types";
import { Helmet } from "react-helmet";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import ReactLoading from 'react-loading'


import { setRequestStatus } from "../../application/actions/miscActions";
import {
  searchMerchantCategory,
  setTypeState,
  setIsLoadMore,
} from "../../application/actions/merchantActions";

import { useDidMount } from "../../application/hooks";

import {
  MerchantCategorySearch,
  BottomSpace,
  BottomNav,
  CategoryTopNav,
} from "../components/common";

import {
  MerchantListCategory,
  MerchantItem,
  SearchMerchantItem,
  FilterNearestMerchantItem,
} from "../";
import { t } from "../../miscellaneous/language";

const CategoryContainer = ({ match }) => {
  console.log(match);
  const { categoryId, categoryName } = match.params;
  const dispatch = useDispatch();
  const didMount = useDidMount(true);

  const store = useSelector(
    (state) => ({
      merchants: state.merchants,
      requestStatus: state.app.requestStatus,
      merchantKeyword: state.merchants.merchantKeyword,
      isLoading: state.app.loading,
      auth: state.auth,
    }),
    shallowEqual
  );


  const [filterState, setFilterState] = React.useState({
    nearMe: true,
    district: false,
  });

  React.useEffect(() => {
    if (didMount && store.merchantKeyword) {
      setFilterState({ nearMe: false });
      dispatch(searchMerchantCategory(store.merchantKeyword, categoryId));
    } else if (
      filterState.nearMe ||
      (store.merchantKeyword === "" && !filterState.district)
    ) {
      setFilterState({ nearMe: true });
      dispatch(setIsLoadMore(false));
      dispatch(setTypeState("FILTER_NEAR_ME"));
    }
  }, [
    didMount,
    store.merchantKeyword,
    filterState.nearMe,
    filterState.district,
    dispatch,
  ]);

  React.useEffect(
    () => () => {
      dispatch(setRequestStatus(""));
      // eslint-disable-next-line
    },
    []
  );

  const renderMerchants = (type) => {
    switch (type) {
      case "SEARCH":
        return (
          <SearchMerchantItem
            merchants={store.merchants.searchedItemsCategory.items}
          />
        );
      case "DEFAULT":
        return <MerchantItem merchants={store.merchants.category} />;
      default:
        return (
          <FilterNearestMerchantItem
            merchants={store.merchants.filterNearMeCategory.items}
          />
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>{t.helmet.title.Category}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content={t.helmet.desc.Category}
        />
      </Helmet>
      <CategoryTopNav title={categoryName} />
      <div className="gray-2-background">
        <MerchantCategorySearch />
      </div>
      <MerchantListCategory
        {...store}
        categoryId={categoryId}>
        {renderMerchants(store.merchants.type)}
      </MerchantListCategory>
      <BottomNav />
      <BottomSpace />
      {store.isLoading
        &&
        <div style={{ left:0, right:0, top:0, bottom:0, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'rgba(0,0,0,0.5)', position:'fixed', zIndex:3, paddingTop:100 }}>
          <div style={{ backgroundColor:'white', width:'80%', height:100, display:'flex', flexDirection:'row' }} className="alert">
          <ReactLoading height={50} width={50} type="spin" color="#FF6000" />
          <h2 style={{ marginLeft:20, marginTop:15 }}>Loading</h2>
          </div>
        </div>}
    </>
  );
};

CategoryContainer.propTypes = {
  match: PropType.shape({
    params: PropType.shape({
      categoryId: PropType.number,
    }),
  }).isRequired,
};

export default CategoryContainer;
