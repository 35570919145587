import React from 'react'
import MerchantDetailItem from '../../Merchant/MerchantDetailItem'
import ItemsInCart from './ItemsInCart'

import { formatCurrency } from '../../../miscellaneous/helpers/utils';

import '../Cart.css'
import { t } from '../../../miscellaneous/language';

const CartDetail = ({cart, deliveryFee, totalPrice, totalDiscount, handleOrderNotes, platformFee}) => {

    return (
        <div className="cart-detail-container">
            <MerchantDetailItem merchant_details={cart.merchant}/>
            <div className="cart-detail-main-content">
                <div className="items-in-cart-title">
                    <span>{t.text.orderList}</span>
                </div>
                <div className="items-in-cart-container">
                    {!cart.items ? <span>{t.loading}</span> : cart.items?.map((cartItem, index) => (
                        <ItemsInCart key={'item' + index} items={cartItem}/>
                    ))}
                </div>
                <div className="comment-input-container">
                    <input type="text" onChange={(e) => handleOrderNotes(e.target.value)} placeholder={t.placeholder.noteMerchant}/>
                </div>
                <div className="bill-detail-wrapper">
                    <div className="bill-detail-container">
                        <div className="bill-detail">
                            <span>{t.text.billDetail}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span>{t.text.totalPrice}</span>
                            <span>{formatCurrency(totalPrice)}</span>
                        </div>
                        <div className="bill-detail-content red-color">
                            <span>{t.text.totalDisc}</span>
                            <span>{formatCurrency(totalDiscount)}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span>{t.text.platformFee}</span>
                            <span>{formatCurrency(platformFee)}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span>{t.text.deliveryFee}</span>
                            <span>{formatCurrency(deliveryFee?.data?.result?.Price ? deliveryFee?.data?.result?.Price : 0)}</span>
                        </div>
                        <div className="bill-detail-content to-pay">
                            <span>{t.text.toBePaid}</span>
                            <span>{formatCurrency((totalPrice + (deliveryFee?.data?.result?.Price ? deliveryFee?.data?.result?.Price : 0) + platformFee))}</span> {/* REMOVE 10K (STATIC FEE) */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartDetail
