import React from 'react'
import { t } from '../../../../miscellaneous/language'

const CartDetailButton = ({orderFunction}) => {
    return (
        <div className="button-orange-container" onClick={(e) => {orderFunction()}}>
            <button>{t.btn.confirmOrder}</button>
        </div>
    )
}

export default CartDetailButton
