import React from 'react'
import ItemDetailMainContent from './ItemDetailMainContent'

import './Merchant.css'

const ItemDetail = ({item, itemCat}) => {

    return (
        <div className="item-detail-container">
            {/* <MerchantDetailItem /> */}
            <ItemDetailMainContent item={item} itemCat={itemCat} />
        </div>
    )
}

export default ItemDetail
