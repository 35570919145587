import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const useUserData = (userId, lat, lon, city, host, name, addressName) => {
    const [userData, setUserData] = React.useState(null);

    const fetcher = (url) => axios.get(url, {
        params: {
            userID: userId,
            lat,
            lon,
            city,
            host,
            name,
            addressName
        }
    });


    const {
        data,
        error,
        mutate
    } = useSWR('https://brmarket-api.taximaxim.com/api/v1/name', fetcher);

    React.useEffect(() => {
        if (data) {
            setUserData(data.data);
        }
        if (error) {
            setUserData(null);
        }

        console.log(data)
        // eslint-disable-next-line
    },[data, error]);

    return {
        userData,
        mutate
    }
}

export default useUserData;
