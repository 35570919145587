import React from 'react'
// import HalalLogo from '../../assets/icons/halal-logo.png'

import {
    Star, 
    District,
    PriceLabel,
    MerchantImage
} from '../../assets/'

import './Merchant.css'

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';

const MerchantDetailItem = ({ merchant_details }) => {
    const history = useHistory();
    
    return (
        <div className="merchant-list-card" onClick={(e) => { merchant_details.is_closed === 1 ? alert('Merchant sedang tutup!') : history.push(`/merchant/${merchant_details.id}`) }}>
            <div className="img-container">
                <img src={merchant_details.image} alt="" className="merchant-list-img" onError={(event) => event.target.src = MerchantImage}/>
            </div>
            <div className="merchant-list-description-container">
                <span className="merchant-list-name">{merchant_details.name}</span>
                <span className="merchant-list-desc">{merchant_details.desc}</span>
                <div className="border"></div>
                <div className="merchant-list-detail-container">
                    <div className="merchant-list-detail"><Star id="merchant-icon-info" fill="#FF6000" /> <span>{merchant_details.avg_rating}</span></div>
                    <div className="merchant-list-detail"><District id="merchant-icon-info" /> <span>{merchant_details.merchant_district?.name}</span></div>
                    <div className="merchant-list-detail"><PriceLabel id="merchant-icon-info" /> <span>{formatCurrencyKStyle(merchant_details.min)} - {formatCurrencyKStyle(merchant_details.max)}</span></div>
                    {/* {merchant_details.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant_details.halal ? "Halal" : "Non-Halal"}</span></div> : ''} */}
                    {/* {merchant_details.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant_details.halal ? <HalalLogo id="halal-logo" /> : ""}</span></div> : ''} */}
                </div>
            </div>
        </div>
    )
}

export default MerchantDetailItem
