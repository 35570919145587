const Zh = {
    btn: {
        saveName: '保存名称' ,
        yes: '是的',
        no: '不',
        add: '添加',
        viewCart: '查看我的购物车',
        alertOk: '是的我明白d',
        confirmOrder:'确认您的订单',
        shopping: `我们去购物吧！`,
        status: {
            orderStatus0:'刷新订单状态',
            orderStatus1:'检查交货状态',
            orderStatus9:'来自其他商家的订单'
        }
    },
    text: {
        saveName: '您好，请输入您的姓名再继续 ...',
        item: '物品',
        empty: '售罄',
        orderList: '订单清单',
        billDetail: '账单详情',
        totalPrice: '总价',
        totalDisc: '折扣总额',
        platformFee: '平台费',
        deliveryFee: '送货费',
        toBePaid: '支付数量',
        emptyCart: '您的购物车仍然是空的:(',
        orderPlaced: {
            title: '订单成功制作',
            desc: '等待商人确认您的订单'
        },
        merchantCancel: {
            title: '您的订单已取消',
            desc: '商人取消您的订单'
        },
        merchantconfirm: {
            title: '您的订单已确认',
            desc: '商人确认您的订单'
        },
        itemPickedUp: {
            title: '您的订单正在发货',
            desc: '感谢您使用Maxim服务'
        },
        completeStatus: {
            title: '您的订单完成',
            desc: '感谢您使用Maxim服务'
        },
        userCancel: {
            title: '您的订单已取消',
            desc: '用户取消您的订单'
        },
        buyerName: '买方名称',
        receiveAt: '收到',
        status: '地位',
        paymentType: '付款方式',
        cash: '现金',
        note: '笔记： ',
        noNote: '没有笔记',
        noteMerchant: '注意商人'
    },
    label:{
        user_name: '你的名字： '
    },
    placeholder: {
        name: '输入您的名字',
        searchHome: '搜索商人或项目...',
        searchCategory: "搜索商人",
        noteItemDetail: '在这里写特别笔记',
        searchItem: '搜索项...',
        noteMerchant: "写您的评论或对商人的建议" 
    },
    alert:{
        orderCancel: '该订单被取消',
        nonPartner: '非合作伙伴',
        noteNonPartner: '该商人不是官方的Maxim合作伙伴，产品可用性和价格准确性可能会改变并与实际条件不同。继续？',
        cart: '大车',
        cartEmpty: '购物车中的此项目/菜单只剩下一个数量，减少数量意味着从购物车中删除物品..继续吗？',
        alert: '警报',
        noteProcessOrder: '服务器正在处理您的请求，请等待...如果过程需要太长，请重新启动您的应用程序或检查您的Internet连接。',
        confirmOrder: '确认订单',
        noteConfirmOrder: '您确定要转发订单吗？请在确认订单之前再次检查',
        noteCountDeliveryPrice: '计算运输成本时发生错误，请关闭申请并将其重新打开以继续。',
        noteDeliveryPrice: '在检索您的位置进行邮资计算时发生错误，请关闭申请并重新打开该应用程序，并确保您的GPS继续进行。',
        noteNonPartnerReceipt: '该商人不是正式的Maxim合作伙伴，项目可用性和列出的价格可能有所不同。请根据收据中规定的价格付款。',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - 开始',
            Home: "Foods&Goods",
            Category: "Foods&Goods - 类别商人",
            History: 'Foods&Goods - 历史',
            DetailCart: "Foods&Goods - 购物车细节",
            afterOrder: 'Foods&Goods - 订购后',
            HistoryDetail: 'Foods&Goods - 历史细节',
        },
        desc: {
            start:'开始',
            Home: "Foods&Goods",
            Category: "按类别按类别的商人列表",
            History: '您完成的交易列表',
            DetailCart: "购物车中的项目详细信息",
            afterOrder: '订购后',
            HistoryDetail: '历史细节',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: '历史'
    },
    loading: '加载...',
    data: {
        noDataMerchant: '找不到商人', 
        DataMerchant: '找到商人', 
    },
    bottomNav: {
        Home: '家',
        Explore: '探索',
        Promo: '促销',
        History: '历史',
    },
    status: {
        Open: '打开',
        Close: '关闭',
        case0: '等待商店批准',
        case1: '商店批准的订单',
        case2: '驾驶员马克斯（Maxim）的订单',
        case9: '取消订单',
        caseNotFound: '找不到订单状态',
        orderStatus0 : '等待',
        orderStatus1 : '获得商人的批准',
        orderStatus2 : '被驾驶员捡起',
        orderStatus11 : '完全的',
        orderStatus99 : '取消',
        orderStatusNotFound : '未找到状态'
    }
}

export default Zh