import React from 'react'
import MaximLogo from '../../../../assets/images/maxim-logo.svg'

const ReceiptTopNav = () => {

    return (
        <>
            <div className="receipt-topnav">
                <img src={MaximLogo} alt="" />
                <span className="receipt-topnav-title">Foods&Goods</span>
            </div>  
        </>
    )
}

export default ReceiptTopNav
