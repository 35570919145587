import React, { useState } from 'react'
import MerchantMenuList from './MerchantMenuList'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './Merchant.css'
import { t } from '../../miscellaneous/language';

const MerchantMenuCategory = ({items, detailFunction}) => {

    const [isOpen, setIsOpen] = useState(true)
    
    const toggle = () => setIsOpen(!isOpen);
    
    return (
        <>
            <Accordion
                expanded={isOpen}
                onChange={toggle}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                    <span className="merchant-menu-category-name">{items.name}</span>
                </AccordionSummary>
                {!items ? <span>{t.loading}</span> : items.items?.map((menuItem) => (
                <AccordionDetails>
                    <MerchantMenuList item={menuItem} detailFunction={detailFunction} categoryName={items.name}/>
                </AccordionDetails>
                ))}
            </Accordion>
        </>
    )
}

export default MerchantMenuCategory
