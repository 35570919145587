import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { ExploreSearch } from '..';
import { Link } from 'react-router-dom';

import {
    AngleSmallLeft,
    Home
} from '../../../../assets'

import '../../../../assets/icons/icons.css'

const SearchTopNav = (props) => {

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="white-space"></div>
            <div className="topnav-container">
                <div className="topnav-left-side" onClick={() => {
                    if(props.isClicked) {
                        props.handleDetailClick();
                    }
                    else {
                        history.goBack()
                    }
                }}>
                    <AngleSmallLeft id="back-small-icon" />
                </div>
                <div className="topnav-center-side">
                    <ExploreSearch/>
                </div>
                <Link to="/home" className="topnav-right-side">
                    <Home id="home-small-icon" />
                </Link>
            </div>
        </>
    )
}

export default SearchTopNav
