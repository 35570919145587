import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const useDeliveryFeeCalculator = (startLatitude, startLongitude, startAddressName, endLatitude, endLongitude, endAddressName, host) => {
    const [deliveryFee, setDeliveryFee] = React.useState(null);

    const fetcher = async (url) => await axios.get(url, {
        params: {
            StartLatitude: startLatitude, 
            StartLongitude: startLongitude, 
            StartAddressName: startAddressName,
            EndLatitude: endLatitude, 
            EndLongitude: endLongitude,
            EndAddressName: endAddressName, 
            host
        }
    });

    const {
        data,
        error,
        mutate
    } = useSWR('https://brmarket-api.taximaxim.com/api/v1/delprice', fetcher);

    React.useEffect(() => {
        if (data) {
            setDeliveryFee(data);
        }
        if (error) {
            setDeliveryFee(null);
        }
        // eslint-disable-next-line
    },[data, error]);

    return {
        deliveryFee,
        mutate
    }
}

export default useDeliveryFeeCalculator;
