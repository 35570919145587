import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import ReactLoading from 'react-loading'

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { sendOrder } from "../../application/actions/cartActions";

import {
  BackHomeTopNav,
  CartDetailButton,
  BottomSpace,
  EmptyCart,
  // CartDetailButtonChoosePayment,
} from "../components/common";

import { CartDetail } from "../";

import { useDeliveryFeeCalculator, usePlatformFeeGenerator } from "../../application/hooks";
import { t } from "../../miscellaneous/language";

const CartDetailContainer = () => {
  const dispatch = useDispatch();

  const store = useSelector(
    (state) => ({
      cart: state.cart,
      auth: state.auth,
    }),
    shallowEqual
  );

  const [cartPrice, setCartPrice] = useState({
    totalPrice: 0,
    totalDiscount: 0,
  });

  const { deliveryFee } = useDeliveryFeeCalculator(
    store.cart.merchant?.lat,
    store.cart.merchant?.lon,
    store.cart.merchant?.name,
    store.auth.userData.lat,
    store.auth.userData.lon,
    store.auth.userData.addressName,
    store.auth.userData.host
  );
  const {platformFee} = usePlatformFeeGenerator(
    store.auth.userData.userID,
    store.auth.userData.name,
    store.auth.userData.lat,
    store.auth.userData.lon,
    store.auth.userData.city,
    store.auth.userData.host
  )
  const [orderNotes, setOrderNotes] = useState();
  const [orderedItems, setOrderedItems] = useState([]);

  const [isRequesting, setIsRequesting] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleOrderNotes = (orderNotes) => {
    setOrderNotes(orderNotes);
  };

  const countCartSummary = () => {
    setCartPrice({
      totalPrice: 0,
      totalDiscount: 0,
    });
    // eslint-disable-next-line array-callback-return
    store.cart.items?.map((cartItem) => {
      setCartPrice((prevState) => ({
        ...prevState,
        totalPrice: prevState.totalPrice + cartItem.total * cartItem.qty,
      }));
      if (cartItem.discount) {
        setCartPrice((prevState) => ({
          ...prevState,
          totalDiscount:
            prevState.totalDiscount + cartItem.discount_amount * cartItem.qty,
        }));
      }
    });
  };
   console.log(store.cart)
  React.useEffect(() => {
    countCartSummary();
    prepareOrderedItems();
    if(store?.cart?.items?.length === 0) setLoading(false)
    console.log(deliveryFee);
  }, [store, orderedItems, deliveryFee]);

  const prepareOrderedItems = () => {
    let itemsArray = [];

    // eslint-disable-next-line array-callback-return
    store.cart.items?.map((cartItem) => {
      let tempArray;
      if (cartItem.orderDesc === null) {
        tempArray = {
          item_id: cartItem.id,
          quantity: cartItem.qty,
          price: cartItem.price,
          discount: cartItem.discount,
          discount_amount: cartItem.discount_amount,
          total: cartItem.total * cartItem.qty,
        };
      } else {
        tempArray = {
          item_id: cartItem.id,
          quantity: cartItem.qty,
          price: cartItem.price,
          discount: cartItem.discount,
          discount_amount: cartItem.discount_amount,
          notes: cartItem.orderDesc,
          total: cartItem.total * cartItem.qty,
        };
      }

      itemsArray.push(tempArray);
    });

    if (JSON.stringify(itemsArray) !== JSON.stringify(orderedItems)) {
      setOrderedItems(itemsArray);
    }
  };

  const trySendOrder = () => {
    if (store.cart.merchant?.is_closed === 1) {
      alert("Merchant sedang tutup!");
    } else {
      if (store.auth.userData.lat !== 0 && store.auth.userData.lon !== 0) {
        if (deliveryFee?.data?.result?.Price != null) {
          if (isRequesting) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="alert">
                    <h1>{t.alert.alert}</h1>
                    <p>
                      {t.alert.noteProcessOrder}
                    </p>
                    <div className="alert-button-container">
                      <button className="info-button" onClick={onClose}>
                        {t.btn.alertOk}
                      </button>
                    </div>
                  </div>
                );
              },
            });
          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="alert">
                    <h1>{t.alert.confirmOrder}</h1>
                    <p>
                      {t.alert.noteConfirmOrder}
                    </p>
                    <div className="alert-button-container">
                      <button
                        className="yes-button"
                        onClick={() => {
                          dispatch(
                            sendOrder(
                              store.auth.userData.userID,
                              store.auth.userData.lat,
                              store.auth.userData.lon,
                              store.auth.userData.city,
                              store.auth.userData.host,
                              store.auth.userData.name,
                              store.auth.userData.addressName,
                              store.cart.items?.length,
                              cartPrice.totalPrice, // total price
                              cartPrice.totalDiscount, // total discount
                              deliveryFee?.data.result.Price, // deliv fee
                              cartPrice.totalPrice +
                                deliveryFee?.data.result.Price, // total
                              orderNotes,
                              store.cart.merchant?.id,
                              orderedItems,
                              platformFee
                            )
                          );
                          setIsRequesting(true);
                          setLoading(true)
                          onClose();
                        }}>
                        {t.btn.yes}
                      </button>
                      <button class="no-button" onClick={onClose}>
                        {t.btn.no}
                      </button>
                    </div>
                  </div>
                );
              },
            });
          }
        } else {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="alert">
                  <h1>{t.alert.alert}</h1>
                  <p>
                    {t.alert.noteCountDeliveryPrice}
                  </p>
                  <div className="alert-button-container">
                    <button className="info-button" onClick={onClose}>
                      {t.btn.alertOk}
                    </button>
                  </div>
                </div>
              );
            },
          });
        }
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="alert">
                <h1>{t.alert.alert}</h1>
                <p>
                {t.alert.noteDeliveryPrice}

                </p>
                <div className="alert-button-container">
                  <button className="info-button" onClick={onClose}>
                    {t.btn.alertOk}
                  </button>
                </div>
              </div>
            );
          },
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{t.helmet.title.DetailCart}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={t.helmet.desc.DetailCart} />
      </Helmet>
      <BackHomeTopNav />
      {store.cart.items?.length === 0 ? (
        <>
          <EmptyCart />
        </>
      ) : (
        <>
          <CartDetail
            cart={store.cart}
            handleOrderNotes={handleOrderNotes}
            deliveryFee={deliveryFee}
            totalPrice={cartPrice.totalPrice}
            totalDiscount={cartPrice.totalDiscount}
            platformFee={platformFee}
          />
          {/* {'something' === 'something' ? <CartDetailButtonChoosePayment/> : <CartDetailButton/>} */}
          <CartDetailButton orderFunction={trySendOrder} />
          <BottomSpace class="gray-bottom-space" />
        </>
      )}
        {loading
        &&
        <div style={{ left:0, right:0, top:0, bottom:0, display:'flex', alignItems:'center', justifyContent:'center', backgroundColor:'rgba(0,0,0,0.5)', position:'fixed', zIndex:3, paddingTop:100 }}>
          <div style={{ backgroundColor:'white', width:'80%', height:100, display:'flex', flexDirection:'row' }} className="alert">
          <ReactLoading height={50} width={50} type="spin" color="#FF6000" />
          <h2 style={{ marginLeft:20, marginTop:15 }}>{t.loading}</h2>
          </div>
        </div>}
    </>
  );
};

export default CartDetailContainer;
