import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from "react-router-dom";

import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { setUserdata, authenticateUser, updateName } from '../../application/actions/authAction';

import { useUserData } from '../../application/hooks';

import {
    // TopNav,
    // BottomSpace,
    // BottomNav
} from '../components/common'
import { t } from '../../miscellaneous/language';

const StartContainer = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const store = useSelector((state) => ({
        auth: state.auth
    }), shallowEqual);

    const location = useLocation();
    const searchParam = new URLSearchParams(location.search);
    const userID = searchParam.get('userId');
    const lat = searchParam.get('lat');
    const lon = searchParam.get('lon');
    const city = searchParam.get('city');
    const host = searchParam.get('host');
    const addressName = searchParam.get('addressName');
    const [name, setName] = useState(searchParam.get('userName'));

    const { userData } = useUserData(userID, lat, lon, city, host, name, addressName);

    const handleName = (val) => {
        setName(val);
    }

    const handleUpdateName = async () => {
        await dispatch(updateName(userID, lat, lon, city, name, host, addressName));
        await dispatch(setUserdata(userID, lat, lon, city, host, name, addressName, userData?.data?.running_order));
        await dispatch(authenticateUser());
        history.push('/home');
    }

    React.useEffect(() => {
        if(userData?.data?.name != null) {
            if(userID != null && lat != null && lon != null && city != null && host != null && name != null && addressName != null) {
                dispatch(setUserdata(userID, lat, lon, city, host, name, addressName, userData?.data?.running_order));
    
                if(store.auth?.isAuthenticated) {
                    history.push('/home');
                }
                else {
                    dispatch(authenticateUser());
                }
            }
        }

        // handleUpdateName();

        console.log(userData);
    }, [store, userData]);
    

    return (
        <>
            <Helmet>
                <title>{'Foods&Goods - Start'}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={'Start'} />
            </Helmet>
            {/* <TopNav /> */}
            {userData?.data == null ? 
                <>
                <div className="start-wrapper">
                    <div className="start-container">
                        <span>{t.text.saveName}</span>
                        <label htmlFor="your-name">{t.label.user_name}</label>
                        <input type="text" onChange={(e) => handleName(e.target.value)} defaultValue={name} placeholder={t.placeholder.name} /><br/>
                        <button onClick={handleUpdateName}>{t.btn.saveName}</button>
                    </div>
                </div>
                </>
                : ''
            }
            {/* <BottomSpace /> */}
            {/* <BottomNav /> */}
        </>
    )
}

export default StartContainer
