import React from 'react'
import { useHistory } from 'react-router-dom';
import { BiSearch } from "react-icons/bi";

import './SearchBar.css'
import { t } from '../../../../miscellaneous/language';

const MerchantSearch = () => {
    const history = useHistory();

    return (
        <div className="search-input-container">
            <input 
                type="text" 
                className="search-input" 
                onClick={() => {history.push('/explore')}}
                placeholder={t.placeholder.searchHome} 
            />
            <BiSearch size={23} className="search-icon" />
        </div>
    )
}

export default MerchantSearch