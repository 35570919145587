const Id = {
    btn: {
        saveName: 'Simpan nama' ,
        yes: 'Ya',
        no: 'Tidak',
        add: 'Tambah',
        viewCart: 'Lihat Keranjang',
        alertOk: 'Ya, saya mengerti',
        confirmOrder:'konfirmasi pesanan kamu',
        shopping: 'Ayo belanja!',
        status: {
            orderStatus0:'Refresh status pesanan',
            orderStatus1:'Telusuri status pengantaran',
            orderStatus9:'Pesan dari merchant lainnya'
        }
    },
    text: {
        saveName: 'Halo Masukan nama kamu sebelum memulai',
        item: 'items',
        empty: 'Habis',
        orderList: 'LIST PESANAN',
        billDetail: 'RINGKASAN TAGIHAN',
        totalPrice: 'Total Harga',
        totalDisc: 'Total Diskon',
        platformFee: 'Biaya Jasa',
        deliveryFee: 'Biaya Ongkos Kirim',
        toBePaid: 'Yang Dibayarkan',
        emptyCart: 'Keranjang kamu masih kosong nih :(',
        orderPlaced: {
            title: 'pesanan berhasil dibuat',
            desc: 'Menunggu merchant mengkonfirmasi pesananmu'
        },
        merchantCancel: {
            title: 'pesanan kamu dibatalkan',
            desc: 'Merchant membatalkan pesananmu'
        },
        merchantconfirm: {
            title: 'pesanan kamu dikonfirmasi',
            desc: 'Merchant mengkonfirmasi pesananmu'
        },
        itemPickedUp: {
            title: 'pesanan kamu sedang dikirim',
            desc: 'Terima kasih telah menggunakan layanan Maxim'
        },
        completeStatus: {
            title: 'pesanan kamu selesai',
            desc: 'Terima kasih telah menggunakan layanan Maxim'
        },
        userCancel: {
            title: 'pesanan kamu dibatalkan',
            desc: 'User membatalkan pesananmu'
        },
        buyerName: 'Nama Pembeli',
        receiveAt: 'Diterima pada',
        status: 'Status',
        paymentType: 'Jenis Pembayaran',
        cash: 'Tunai',
        note: 'Catatan: ',
        noNote: 'Tidak ada Catatan',
        noteMerchant: 'Catatan untuk merchant'
    },
    label:{
        user_name: 'Nama Anda: '
    },
    placeholder: {
        name: 'Masukan nama anda',
        searchHome: 'Cari Merchant atau barang ...',
        searchCategory: "Cari Merchant",
        noteItemDetail: 'masukkan komen disini jika ada catatan khusus',
        searchItem: 'Cari barang ...',
        noteMerchant: "Tulis komentar atau saran untuk merchant" 
    },
    alert:{
        orderCancel: 'Pesanan ini dibatalkan',
        nonPartner: 'Non Partner',
        noteNonPartner: 'Merchant ini bukanlah partner resmi Maxim, ketersediaan produk dan ketepatan harga dapat berubah serta berbeda dengan kondisi aktual.. Lanjutkan? (Harap minta nota pembelian dan bayar sesuai dengan yang tertera pada nota resmi penjual beserta ongkos kirim)',
        cart: 'Keranjang',
        cartEmpty: 'Barang/menu tersisa satu buah dalam keranjang, mengurangi kuantitas akan menghapus barang/menu dari keranjang.. Lanjutkan?',
        alert: 'Peringatan',
        noteProcessOrder: 'Server sedang memproses permintaan anda, harap tunggu... Apabila proses terlalu lama, silahkan restart aplikas anda atau cek koneksi internet anda..',
        confirmOrder: 'Konfirmasi Pesanan',
        noteConfirmOrder: 'Apakah anda yakin ingin meneruskan pesanan anda? Harap cek kembali sebelum melakukan konfirmasi pemesanan',
        noteCountDeliveryPrice: 'Terjadi kesalahan pada saat penghitungan biaya ongkos kirim, harap tutup aplikasi dan buka kembali untuk melanjutkan..',
        noteDeliveryPrice: 'Terjadi kesalahan pada saat pengambilan lokasi anda untuk penghitungan ongkos kirim, harap tutup aplikasi dan buka kembali serta pastikan gps anda menyala untuk melanjutkan..',
        noteNonPartnerReceipt: 'Toko ini bukan merupakan partner resmi Maxim, ketersediaan barang dan harga yang tercantum dapat berbeda. Silakan lakukan pembayaran sesuai nominal yang tercantum pada struk pembayaran.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Start',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Kategori Merchant",
            History: 'Foods&Goods - Riwayat',
            DetailCart: "Foods&Goods - Detil Keranjang",
            afterOrder: 'Foods&Goods - Setelah Pemesanan',
            HistoryDetail: 'Foods&Goods - Setelah Pemesanan',
        },
        desc: {
            start:'Start',
            Home: "Foods&Goods",
            Category: "Daftar Merchant berdasarkan kategori",
            History: 'Daftar transaksi yang pernah anda lakukan',
            DetailCart: "Detil barang di keranjang anda",
            afterOrder: 'Setelah Pemesanan',
            HistoryDetail: 'Setelah Pemesanan',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'Riwayat'
    },
    loading: 'Loading ...',
    data: {
        noDataMerchant: 'Tidak ada merchant ditemukan', 
        DataMerchant: 'Merchant ditemukan', 
    },
    bottomNav: {
        Home: 'Beranda',
        Explore: 'Eksplor',
        Promo: 'Promo',
        History: 'Riwayat',
    },
    status: {
        Open: 'Buka',
        Close: 'Tutup',
        case0: 'Menunggu persetujuan Toko',
        case1: 'Pesanan disetujui oleh Toko',
        case2: 'Pesanan dibawa oleh Driver Maxim',
        case9: 'Pesanan dibatalkan',
        caseNotFound: 'Status Pesanan Tidak Ditemukan',
        orderStatus0 : 'Menunggu',
        orderStatus1 : 'Disetujui oleh Merchant',
        orderStatus2 : 'Dijemput oleh Driver',
        orderStatus11 : 'Completed',
        orderStatus99 : 'Cancelled',
        orderStatusNotFound : 'Status tidak diketahui'
    }
}

export default Id