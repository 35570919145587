const Th = {
    btn: {
        saveName: 'บันทึกชื่อ' ,
        yes: 'ใช่',
        no: 'ใช่',
        add: 'เพิ่ม',
        viewCart: 'ดูรถเข็นของฉัน',
        alertOk: 'ใช่ฉันเข้าใจแล้ว',
        confirmOrder:'ยืนยันการสั่งซื้อ',
        shopping: `มาช้อปกัน!`,
        status: {
            orderStatus0:'รีเฟรชสถานะคำสั่งซื้อ',
            orderStatus1:'ตรวจสอบสถานะการจัดส่ง',
            orderStatus9:'สั่งซื้อจากร้านค้าอื่น ๆ'
        }
    },
    text: {
        saveName: 'สวัสดี กรุณากรอกชื่อก่อนดำเนินการต่อ...',
        item: 'รายการ',
        empty: 'ขายหมดแล้ว',
        orderList: 'รายการสั่งซื้อ',
        billDetail: 'รายละเอียดบิล',
        totalPrice: 'ราคารวม',
        totalDisc: 'ยอดรวมส่วนลด',
        platformFee: 'ค่าธรรมเนียมแพลตฟอร์ม',
        deliveryFee: 'ค่าส่ง',
        toBePaid: 'ต้องจ่าย',
        emptyCart: 'ตะกร้าสินค้าของคุณยังว่างอยู่ :(',
        orderPlaced: {
            title: 'สั่งซื้อได้สำเร็จ',
            desc: 'รอให้ผู้ค้ายืนยันคำสั่งซื้อของคุณ'
        },
        merchantCancel: {
            title: 'คำสั่งซื้อของคุณถูกยกเลิก',
            desc: 'ผู้ค้ายกเลิกคำสั่งซื้อของคุณ'
        },
        merchantconfirm: {
            title: 'คำสั่งซื้อของคุณได้รับการยืนยัน',
            desc: 'ผู้ค้ายืนยันคำสั่งซื้อของคุณ'
        },
        itemPickedUp: {
            title: 'คำสั่งซื้อของคุณกำลังถูกจัดส่ง',
            desc: 'ขอบคุณที่ใช้บริการ Maxim'
        },
        completeStatus: {
            title: 'คำสั่งซื้อของคุณเสร็จสมบูรณ์',
            desc: 'ขอบคุณที่ใช้บริการ Maxim'
        },
        userCancel: {
            title: 'คำสั่งซื้อของคุณถูกยกเลิก',
            desc: 'ผู้ใช้ยกเลิกคำสั่งซื้อของคุณ'
        },
        buyerName: 'ชื่อผู้ซื้อ',
        receiveAt: 'รับที่',
        status: 'สถานะ',
        paymentType: 'ประเภทการชำระเงิน',
        cash: 'เงินสด',
        note: 'บันทึก: ',
        noNote: 'ไม่มีโน้ต',
        noteMerchant: 'หมายเหตุสำหรับผู้ค้า'
    },
    label:{
        user_name: 'ชื่อของคุณ: '
    },
    placeholder: {
        name: 'ป้อนชื่อของคุณ',
        searchHome: 'ค้นหาผู้ค้าหรือรายการ ...',
        searchCategory: "ค้นหาผู้ค้า",
        noteItemDetail: 'เขียนที่นี่เพื่อบันทึกพิเศษ',
        searchItem: 'ค้นหารายการ ...',
        noteMerchant: "เขียนความคิดเห็นหรือข้อเสนอแนะของคุณสำหรับผู้ค้า" 
    },
    alert:{
        orderCancel: 'คำสั่งนี้ถูกยกเลิก',
        nonPartner: 'ไม่ใช่หุ้นส่วน',
        noteNonPartner: 'ผู้ค้ารายนี้ไม่ใช่พันธมิตรสูงสุดอย่างเป็นทางการความพร้อมใช้งานของผลิตภัณฑ์และความแม่นยำของราคาอาจเปลี่ยนแปลงและแตกต่างจากเงื่อนไขจริง .. ดำเนินการต่อ?',
        cart: 'รถเข็น',
        cartEmpty: 'มีเพียงปริมาณเดียวที่เหลือสำหรับรายการ/เมนูนี้ในรถเข็นของคุณการลดปริมาณหมายถึงการลบรายการออกจากรถเข็นของคุณ .. ดำเนินการต่อ?',
        alert: 'เตือน',
        noteProcessOrder: 'เซิร์ฟเวอร์กำลังประมวลผลคำขอของคุณโปรดรอ ... หากกระบวนการใช้เวลานานเกินไปโปรดรีสตาร์ทแอปพลิเคชันของคุณหรือตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ ..',
        confirmOrder: 'ยืนยันรายการ',
        noteConfirmOrder: 'แน่ใจหรือว่าต้องการส่งต่อคำสั่งซื้อของคุณ? โปรดตรวจสอบอีกครั้งก่อนที่จะยืนยันคำสั่งซื้อ',
        noteCountDeliveryPrice: 'เกิดข้อผิดพลาดในขณะที่คำนวณค่าจัดส่งโปรดปิดแอปพลิเคชันและเปิดใหม่เพื่อดำเนินการต่อ ..',
        noteDeliveryPrice: 'เกิดข้อผิดพลาดในขณะที่ดึงตำแหน่งของคุณสำหรับการคำนวณทางไปรษณีย์โปรดปิดแอปพลิเคชันและเปิดใหม่และตรวจสอบให้แน่ใจว่า GPS ของคุณกำลังดำเนินการต่อไป ..',
        noteNonPartnerReceipt: 'ผู้ค้ารายนี้ไม่ใช่พันธมิตรสูงสุดอย่างเป็นทางการความพร้อมใช้งานของรายการและราคาที่ระบุไว้อาจแตกต่างกันไป โปรดชำระเงินตามราคาที่ระบุไว้ในใบเสร็จรับเงิน',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - เริ่ม',
            Home: "Foods&Goods",
            Category: "Foods&Goods - หมวดหมู่พ่อค้า",
            History: 'Foods&Goods - ประวัติศาสตร์',
            DetailCart: "Foods&Goods - รายละเอียดรถเข็น",
            afterOrder: 'Foods&Goods - หลังสั่งซื้อ',
            HistoryDetail: 'Foods&Goods - รายละเอียดประวัติ',
        },
        desc: {
            start:'เริ่ม',
            Home: "Foods&Goods",
            Category: "รายชื่อผู้ค้าตามหมวดหมู่",
            History: 'รายการธุรกรรมที่คุณทำ',
            DetailCart: "รายละเอียดรายการในรถเข็นของคุณ",
            afterOrder: 'หลังสั่งซื้อ',
            HistoryDetail: 'รายละเอียดประวัติ',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'ประวัติศาสตร์'
    },
    loading: 'กำลังโหลด ...',
    data: {
        noDataMerchant: 'ไม่พบพ่อค้า', 
        DataMerchant: 'พบพ่อค้า', 
    },
    bottomNav: {
        Home: 'บ้าน',
        Explore: 'สำรวจ',
        Promo: 'โปรโมชั่น',
        History: 'ประวัติศาสตร์',
    },
    status: {
        Open: 'เปิด',
        Close: 'ปิด I',
        case0: 'รอการอนุมัติร้านค้า',
        case1: 'คำสั่งซื้อที่ได้รับอนุมัติจากร้านค้า',
        case2: 'คำสั่งซื้อที่นำโดย maxim ไดรเวอร์',
        case9: 'คำสั่งยกเลิก',
        caseNotFound: 'ไม่พบสถานะการสั่งซื้อ',
        orderStatus0 : 'ซึ่งรอคอย',
        orderStatus1 : 'ได้รับอนุมัติจากผู้ค้า',
        orderStatus2 : 'เลือกโดยคนขับ',
        orderStatus11 : 'สมบูรณ์',
        orderStatus99 : 'ยกเลิก',
        orderStatusNotFound : 'ไม่พบสถานะ'
    }
}

export default Th