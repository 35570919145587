import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
    Home,
    AngleSmallLeft
} from '../../../../assets'

import '../../../../assets/icons/icons.css'
import { useDispatch } from 'react-redux';
import { clearMerchantsDetails } from '../../../../application/actions/merchantActions';

const MerchantDetailTopNav = (props) => {

    const dispatch = useDispatch()
    const history = useHistory();

    const [scrolled, setIsScrolled] = useState(false);

    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 60 ){
          setIsScrolled(true);
        }
        else{
          setIsScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })
    
    let x=['none'];
    if(scrolled) {
        x.push('merchant-detail-topnav-center-side');
    }

    return (
        <>
            <div className="white-space"></div>
            <div className="topnav-container">
                <div className="topnav-left-side" onClick={() => {
                    if(props.isClicked) {
                        props.handleDetailClick();
                    }
                    else {
                        dispatch(clearMerchantsDetails())
                        history.goBack()
                    }
                }}>
                    <AngleSmallLeft id="back-small-icon" />
                </div>
                <div className={x.join(" ")}>
                    <span className="topnav-title-text">{props.title}</span>
                </div>
                <Link to="/home" className="topnav-right-side">
                    <Home id="home-small-icon" />
                </Link>
            </div>
        </>
    )
}

export default MerchantDetailTopNav
