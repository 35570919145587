import React from 'react'
import PropType from 'prop-types';
import { useHistory } from 'react-router-dom';

const PromoDetailMerchantListContainer = ({ merchants }) => {
    const history = useHistory();

    return (
        <div className="promo-detail-merchant-list-container">
            <span className="promo-detail-merchant-list-text">Daftar Merchant</span>
            {merchants.map((merchant, index) => (
                <div key={index} className="promo-detail-merchant-list-card" onClick={(e) => {history.push(`/merchant/${merchant.id}`)}}>
                    <img src={merchant.image} alt="" />
                    <span className="promo-detail-merchant-name">{merchant.name}</span>
                </div>
            ))}
        </div>
    )
}

PromoDetailMerchantListContainer.propTypes = {
    merchants: PropType.array.isRequired
}

export default PromoDetailMerchantListContainer

