const Es = {
    btn: {
        saveName: 'Guardar nombre' ,
        yes: 'Sí',
        no: 'No',
        add: 'Agregar',
        viewCart: 'ver mi carrito',
        alertOk: 'Si entiendo',
        confirmOrder:'confirma tu orden',
        shopping: `¡Vamos de compras!`,
        status: {
            orderStatus0:'Actualizar estado del pedido',
            orderStatus1:'Consultar estado de entrega',
            orderStatus9:'Pedido de otros comerciantes'
        }
    },
    text: {
        saveName: 'Hola, por favor ingrese su nombre antes de continuar...',
        item: 'articulos',
        empty: 'Agotado',
        orderList: 'LISTA DE PEDIDOS',
        billDetail: 'DETALLE DE LA FACTURA',
        totalPrice: 'Precio total',
        totalDisc: 'Descuento total',
        platformFee: 'Tarifa de plataforma',
        deliveryFee: 'Tarifa de entrega',
        toBePaid: 'A pagar',
        emptyCart: 'Tu carrito de compras todavía está vacío :(',
        orderPlaced: {
            title: 'orden hecho con éxito',
            desc: 'Esperando que el comerciante confirme su pedido'
        },
        merchantCancel: {
            title: 'Su pedido está cancelado',
            desc: 'El comerciante cancela su pedido'
        },
        merchantconfirm: {
            title: 'Su pedido está confirmado',
            desc: 'El comerciante confirma su pedido'
        },
        itemPickedUp: {
            title: 'Tu pedido está siendo enviado',
            desc: 'Gracias por usar el servicio máximo'
        },
        completeStatus: {
            title: 'Tu pedido está completo',
            desc: 'Gracias por usar el servicio máximo'
        },
        userCancel: {
            title: 'Su pedido está cancelado',
            desc: 'El usuario cancela su pedido'
        },
        buyerName: 'Nombre del comprador',
        receiveAt: 'Recibir a',
        status: 'Estado',
        paymentType: 'Tipo de pago',
        cash: 'Dinero en efectivo',
        note: 'Nota: ',
        noNote: 'Sin nota',
        noteMerchant: 'Nota para comerciante'
    },
    label:{
        user_name: 'Su nombre: '
    },
    placeholder: {
        name: 'Ingrese su nombre',
        searchHome: 'Buscar comerciante o artículo ...',
        searchCategory: "Búsqueda del comerciante",
        noteItemDetail: 'Escribe aquí para notas especiales',
        searchItem: 'Artículo de búsqueda ...',
        noteMerchant: "Escriba su comentario o sugerencia para el comerciante" 
    },
    alert:{
        orderCancel: 'Este pedido se cancela',
        nonPartner: 'No socio',
        noteNonPartner: 'Este comerciante no es un socio oficial de máxima, la disponibilidad de productos y la precisión de los precios pueden cambiar y diferir de las condiciones reales ... ¿Continuar?',
        cart: 'Carro',
        cartEmpty: 'Solo queda una cantidad para este elemento/menú en su carrito, disminuir la cantidad significa eliminar los artículos de su carrito. ¿Continuar?',
        alert: 'Alerta',
        noteProcessOrder: 'El servidor está procesando su solicitud, espere ... Si el proceso lleva demasiado tiempo, reinicie su aplicación o verifique su conexión a Internet.',
        confirmOrder: 'Confirmar pedido',
        noteConfirmOrder: '¿Estás seguro de que quieres reenviar tu pedido? Verifique nuevamente antes de confirmar el pedido',
        noteCountDeliveryPrice: 'Se produjo un error al calcular el costo de envío, cierre la aplicación y vuelva a abrirla para continuar.',
        noteDeliveryPrice: 'Se produjo un error al recuperar su ubicación para los cálculos de franqueo, cierre la aplicación y vuelva a abrirla y asegúrese de que su GPS esté activado para continuar.',
        noteNonPartnerReceipt: 'Este comerciante no es un socio oficial de Maxim, la disponibilidad de artículos y los precios enumerados pueden variar. Haga un pago de acuerdo con el precio indicado en el recibo.',
    },
    helmet: {
        title:{
            start: 'Foods&Goods - Comienzo',
            Home: "Foods&Goods",
            Category: "Foods&Goods - Comerciante de categoría",
            History: 'Foods&Goods - Historia',
            DetailCart: "Foods&Goods - Detalle del carrito",
            afterOrder: 'Foods&Goods - Después de la orden',
            HistoryDetail: 'Foods&Goods - Detalle de la historia',
        },
        desc: {
            start:'Comienzo',
            Home: "Foods&Goods",
            Category: "Lista de comerciantes por categoría",
            History: 'Lista de transacciones que ha realizado',
            DetailCart: "Detalles del artículo en su carrito",
            afterOrder: 'Después de la orden',
            HistoryDetail: 'Detalle de la historia',
        }
    },
    topNav: {
        title: 'Foods&Goods'
    },
    backTopNav: {
        History: 'HIstoria'
    },
    loading: 'Cargando ...',
    data: {
        noDataMerchant: 'No se encuentran comerciante', 
        DataMerchant: 'Comerciante encontrado', 
    },
    bottomNav: {
        Home: 'Casa',
        Explore: 'Explorar',
        Promo: 'Promoción',
        History: 'Historia',
    },
    status: {
        Open: 'Abierto',
        Close: 'Cerrar',
        case0: 'Esperando la aprobación de la tienda',
        case1: 'Pedido aprobado por la tienda',
        case2: 'Orden traído por la máxima del conductor',
        case9: 'Orden cancelada',
        caseNotFound: 'Estado de pedido no encontrado',
        orderStatus0 : 'Esperando',
        orderStatus1 : 'Aprobado por el comerciante',
        orderStatus2 : 'Recogido por el conductor',
        orderStatus11 : 'Terminado',
        orderStatus99 : 'Cancelado',
        orderStatusNotFound : 'Estado no encontrado'
    }
}

export default Es