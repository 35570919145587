import React from 'react'
import PropType from 'prop-types';
import Rating from 'react-rating';

import { formatCurrency } from '../../miscellaneous/helpers/utils';

import {
    MerchantImage,
    Star, 
    StarBorder
} from '../../assets'

import { useHistory } from 'react-router-dom';

import './History.css'
import { t } from '../../miscellaneous/language';

const HistoryItem = ({ data, updateRating}) => {
    const history = useHistory();

    const handleClick = (value, id) => {
        if(value === "redirect") {
            history.push(`/historydetails/` + id)
            // window.location = `/historydetails/` + id;
        }
        else {
            updateRating(value, id);
        }
    }

    const statusType = (type) => {
        switch(type) {
            case 0: {
                return t.status.case0
            }
            case 1: {
                return t.status.case1
            }
            case 2: {
                return t.status.case2
            }
            case 9: {
                return t.status.case9
            }
            default: { return t.status.caseNotFound }
        }
    }

    return (
        <>
            {data.map((data, index) => (
                <div key={index} className={data.status === 2 || data.status === 9 ? 'history-card white' : 'history-card orange'}>
                    <div className="history-item-container" onClick={() => data.status !== 9 ? handleClick('redirect', data.id) : alert(t.alert.orderCancel)}>
                        <div className="history-detail-image-container">
                            <img src={data.merchant.image} alt="" onError={(event) => event.target.src = MerchantImage} />
                        </div>
                        <div className="history-detail">
                            <span className="history-detail-name">{data.merchant.name}</span>
                            <span className="history-detail-days">{data.day}</span>
                            <div className="border"></div>
                            <div className="history-detail-item-status">
                                <span className="history-detail-item">{data.order_items.length} | {formatCurrency(data.total_price)}</span>
                                <span className="history-detail-status">{statusType(data.status)}</span>
                            </div>
                        </div>
                    </div>
                    {data.status === 2  ?
                        <div className="rating">
                            <Rating
                                initialRating={data.rating}
                                emptySymbol={<StarBorder id="star-border-medium-icon" />}
                                fullSymbol={<Star id="star-medium-icon" />}
                                onChange={(e) => handleClick(e, data.id)}
                                readonly={(data.rating > 0 ? true : false)}
                            /> 
                        </div>
                        : ''
                    }   
                </div>
            ))}
        </>
    )
}

HistoryItem.propTypes = {
    data: PropType.array.isRequired
}

export default HistoryItem
