import axios from 'axios';

const baseURL = 'https://brmarket-api.taximaxim.com/api/v1/promo';

export const getPromoAPI = async (userID, lat, lon, city, host, name, addressName, page) => {
    const res = await axios.get(`${baseURL}/slider`, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
        }
    });

    return res.data;
}

export const getPromoDetailAPI = async (userID, lat, lon, city, host, name, addressName, promoId, page) => {
    const res = await axios.get(`${baseURL}/slider/${promoId}`, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
        }
    });

    return res.data;
}

export const getPromoItemAPI = async (userID, lat, lon, city, host, name, addressName, page) => {
    const res = await axios.get(`${baseURL}/item`, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
        }
    });

    return res.data;
}

export const getPromoNewAPI = async (userID, lat, lon, city, host, name, addressName, page) => {
    const res = await axios.get(`${baseURL}/merchant`, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
        }
    });

    return res.data;
}