import React from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { setLoading } from "../../application/actions/miscActions";
import {
  filterNearestMerchant,
  searchMerchant,
  getMerchants,
  setIsLoadMore,
  clearCategoryMerchantState,
  clearFilterNearMeCategoryState,
  clearFilterDistrictCategoryState,
} from "../../application/actions/merchantActions";

import "../components/common/Filter/Filter.css";
import "./Merchant.css";
import {t} from '../../miscellaneous/language'

const MerchantList = (props) => {
  const { 
    merchants, 
    children 
  } = props;

  const [pageSize, setPageSize] = React.useState(1);
  const [pageNear, setPageNear] = React.useState(1);
  const [pageSearch, setPageSearch] = React.useState(1);

  const dispatch = useDispatch();

  const fetchNearestMerchants = (page) => dispatch(filterNearestMerchant(page));
  const fetchSearchMerchants = (keyword, page) =>
    dispatch(searchMerchant(keyword, page));
  const fetchMerchants = (page) => dispatch(getMerchants(page));
  const setLoadMore = (status) => dispatch(setIsLoadMore(status));

  React.useEffect(() => {
    if (
      merchants.filterNearMe.items.length === 0 &&
      merchants.type === "FILTER_NEAR_ME"
    ) {
      fetchNearestMerchants();
    } else if (merchants.items.length === 0 && merchants.type === "DEFAULT") {
      fetchMerchants();
    }

    dispatch(clearCategoryMerchantState());
    dispatch(clearFilterNearMeCategoryState());
    dispatch(clearFilterDistrictCategoryState());

    return () => dispatch(setLoading(false));
    // eslint-disable-next-line
  }, [
    merchants.filterNearMe.items.length,
    merchants.items.length,
    merchants.type,
    pageSize,
    pageSearch,
    pageNear,
    dispatch,
  ]);

  const fetchNextData = () => {
    if (!merchants.isLoadMore) {
      if (dataHasMore()) {
        switch (merchants.type) {
          case "SEARCH":
            setLoadMore(true);
            setPageSearch(pageSearch + 1);
            fetchSearchMerchants(merchants.keyword, pageSearch + 1);
            break;
          case "DEFAULT":
            setLoadMore(true);
            setPageSize(pageSize + 1);
            fetchMerchants(pageSize + 1);
            break;
          default:
            setLoadMore(true);
            setPageNear(pageNear + 1);
            console.log(pageNear);
            fetchNearestMerchants(pageNear + 1);
        }
      }
    }
  };

  const lengthData = () => {
    let length = 0;
    switch (merchants.type) {
      case "SEARCH":
        length = merchants.searchedItems.items.length;
        break;
      case "DEFAULT":
        length = merchants.items.length;
        break;
      default:
        length = merchants.filterNearMe.items.length;
    }
    return length;
  };

  const dataHasMore = () => {
    let isMore = false;
    switch (merchants.type) {
      case "SEARCH":
        isMore =
          merchants.searchedItems.items.length !==
          merchants.searchedItems.total;
        break;
      case "DEFAULT":
        isMore = merchants.items.length !== merchants.items.total;
        break;
      default:
        isMore =
          merchants.filterNearMe.items.length !== merchants.filterNearMe.total;
    }
    return isMore;
  };

  return (
    <>
      <InfiniteScroll
        dataLength={lengthData}
        next={fetchNextData}
        hasMore={dataHasMore}
        scrollThreshold={1}
        loader={dataHasMore ? <></> : <h4>{t.loading}</h4>}>
        <div className="merchant-list-count">
          <span>
          {merchants.type === "SEARCH"
              ? lengthData()
              : merchants.type === "DEFAULT"
              ? lengthData()
              : merchants.type === "FILTER_DISTRICT"
              ? lengthData()
              : lengthData()}{" "}
            {t.data.DataMerchant}
          </span>
        </div>

        {merchants.type === "SEARCH" &&
        merchants.searchedItems.items.length === 0 ? (
          <span>
            <center>{t.data.noDataMerchant}</center>
          </span>
        ) : merchants.type === "DEFAULT" && merchants.items.length === 0 ? (
          <span>
            <center>{t.data.noDataMerchant}</center>
          </span>
        ) : (
          children
        )}
      </InfiniteScroll>
    </>
  );
};

MerchantList.defaultProps = {
  requestStatus: null,
};

MerchantList.propTypes = {
  merchants: PropType.object.isRequired,
  requestStatus: PropType.string,
  district: PropType.object,
  children: PropType.oneOfType([PropType.arrayOf(PropType.node), PropType.node])
    .isRequired,
};

export default MerchantList;
