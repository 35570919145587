import React from 'react'
import { useHistory } from 'react-router-dom';

import {
    Star, 
    District, 
    PriceLabel,
    MerchantImage
} from '../../assets'

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';

const MerchantResult = ({item}) => {

    const history = useHistory();

    return (
        <div className="explore-result-container" onClick={(e) => { item.is_closed === 1 ? alert('Merchant sedang tutup!') : history.push(`/merchant/${item.id}`) }}>
            <div className="explore-merchant-list-card">
            {item.is_closed === 0 ? 
                        <div className="img-container">
                            <img src={item.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                            {/* <div className="merchant-list-img-status">status</div> */}
                        </div>
                        :
                        <div className="merchant-list-img" style={{backgroundImage:"url(" + item.image + ")", marginRight:10}} >
                           <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}>{item.is_closed === 0 ? "Buka": "Tutup"}</p>
                        </div>
                    }
                <div className="merchant-list-description-container">
                    <span className="merchant-list-name">{item.name}</span>
                    <span className="merchant-list-desc">{item.desc}</span>
                    <div className="border"></div>
                    <div className="merchant-list-detail-container">
                        <div className="merchant-list-detail"><span><Star id="merchant-icon-info" fill="#FF6000" /> {item.avg_rating}</span></div>
                        <div className="merchant-list-detail"><span><District id="merchant-icon-info" /> {item.district}</span></div>
                        <div className="merchant-list-detail"><span><PriceLabel id="merchant-icon-info" /> {formatCurrencyKStyle(item.min)} - {formatCurrencyKStyle(item.max)}</span></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MerchantResult
