import React from 'react'
import { Helmet } from 'react-helmet'

import {
    BackTitleTopNav,
    BottomNav,
    BottomSpace
} from '../components/common';

import {
    HistoryList
} from '../'

import {t} from '../../miscellaneous/language'
const HistoryContainer = () => {
    return (
        <>
            <Helmet>
                <title>{t.helmet.title.History}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={t.helmet.desc.History} />
            </Helmet>
            <BackTitleTopNav title={t.backTopNav.History} />
            <HistoryList />
            <BottomSpace class="gray-bottom-space" />
            <BottomNav />
        </>
    )
}

export default HistoryContainer
