import React from 'react'
import MerchantMenuCategory from './MerchantMenuCategory'

import './Merchant.css'
import { t } from '../../miscellaneous/language'

const MerchantMenuContainer = ({ items, detailFunction }) => {
    return (
        <div className="merchant-menu-container">
            {!items ? <span>{t.loading}</span> : items?.map((catItems) => (
                <MerchantMenuCategory items={catItems} detailFunction={detailFunction}/>
            ))}
        </div>
    )
}

export default MerchantMenuContainer
