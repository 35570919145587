import React from 'react'

import './PublicLink.css'
import '../Cart/Cart.css'
import { formatCurrency } from '../../miscellaneous/helpers/utils'
import { t } from '../../miscellaneous/language'


const Receipt = ({ orderData }) => {

    React.useEffect(() => {
        console.log(orderData)
    }, [orderData])


    return (
        <div className="receipt-wrapper">
            <div className="receipt-container">
                <div className="receipt-header">
                    <span className="order-code">{orderData?.data?.code}</span>
                </div>
                <div className="receipt-info-container">
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.buyerName}</span>
                        <div className="receipt-client-content orange-color bold">{orderData?.data?.users.name}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.receiveAt}</span>
                        <div className="receipt-client-content gray-1-color">{orderData?.data?.received_at ? orderData?.data?.received_at : "Belum diterima"}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.status}</span>
                        <div className="receipt-client-content">{(orderData?.data?.status === 0 ? t.status.orderStatus0 : orderData?.data?.status === 1 ? t.status.orderStatus1 : orderData?.data?.status === 2 ? t.status.orderStatus2 : orderData?.data?.status=== 11 ? t.status.orderStatus11 : orderData?.data?.status=== 99 ? t.status.orderStatus99 : t.status.orderStatusNotFound)}</div>
                    </div>
                    <div className="receipt-info">
                        <span className="receipt-client-title">{t.text.paymentType}</span>
                        <div className="receipt-client-content">{t.text.cash}</div>
                    </div>
                </div>
                <div className="order-items-container">
                    <span className="order-items-text">{t.text.orderList}</span>
                </div>
                <div className="receipt-order-detail-wrapper">
                    {orderData && orderData.data?.order_items?.map((item, index) => (
                        <div className="receipt-order-detail-container">
                            <div className="receipt-item-container">
                                <span className="receipt-item-name">{item.items?.name}</span>
                                <div className="receipt-item-notes-container">
                                    <span className="receipt-item-notes-title">{t.text.note}</span>
                                    <span className="receipt-item-notes">{item.notes ? item.notes : t.text.noNote}</span>
                                </div>
                            </div>
                            <div className="receipt-item-counted-container">
                                <span className="receipt-item-counted">x{item.quantity}</span>
                            </div>
                            <div className="receipt-item-price-container">
                                <span className="receipt-item-price">{formatCurrency(item.price)}</span>
                            </div>
                        </div> 
                    ))}  
                </div>
                <div className="note-to-merchant">
                    <span className="note-to-merchant-title">{t.text.noteMerchant}</span>
                    <span className="note-to-merchant-content">{(orderData?.data?.notes ? orderData?.data?.notes : t.text.noNote)}</span>
                </div>
                    <div className="receipt-price-detail-container">
                        <div className="bill-detail">
                            <span>{t.text.billDetail}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span className="gray-1-color">{t.text.totalPrice}</span>
                            <span>{formatCurrency(orderData?.data?.total_price)}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span className="gray-1-color">{t.text.totalDisc}</span>
                            <span className="red-color">{formatCurrency(orderData?.data?.total_discount)}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span className="gray-1-color">{t.text.platformFee}</span>
                            <span>{formatCurrency(orderData?.data?.platform_fee)}</span>
                        </div>
                        <div className="bill-detail-content">
                            <span>{t.text.deliveryFee}</span>
                            <span>{formatCurrency(orderData?.data?.delivery_price)}</span>
                        </div>
                        <div className="bill-detail-content to-pay">
                            <span>{t.text.toBePaid}</span>
                            <span>{formatCurrency(orderData?.data?.total + orderData?.data?.delivery_price + orderData?.data?.platform_fee)}</span>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Receipt
