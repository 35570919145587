import React from 'react'

import {
    Edit
} from '../../../assets'

import { useDispatch } from 'react-redux';
import { useCart } from '../../../application/hooks';
import { GoPlus, GoDash } from "react-icons/go";
import { formatCurrency } from '../../../miscellaneous/helpers/utils';
import { setNoteItems, removeFromCart, addQtyItem, removeQtyItem } from '../../../application/actions/cartActions';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import '../Cart.css'
import {t} from '../../../miscellaneous/language'

const ItemsInCart = ({items}) => {
    const dispatch = useDispatch();

    const [isEditing, toggleEdit] = React.useState(false);

    const { itemQtyCounter } = useCart(items.id);

    const onItemAddToCart = (id) => {
        if (itemQtyCounter(id) >= 1) {
            dispatch(addQtyItem(id));
        }
    }
    
    const onItemRemoveFromCart = (id) => {
        if (itemQtyCounter(id) > 1) {
            dispatch(removeQtyItem(id));
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="alert">
                            <h1>{t.alert.cart}</h1>
                            <p>{t.alert.cartEmpty}</p>
                            <div className="alert-button-container">
                                <button
                                    class="yes-button" 
                                    onClick={() => {
                                        dispatch(removeFromCart(id));
                                        onClose();
                                    }}
                                >
                                    {t.btn.yes}
                                </button>
                                <button 
                                    class="no-button" 
                                    onClick={onClose}
                                >
                                    {t.btn.no}
                                </button>
                            </div>
                        </div>
                    )
                }
            })
        }
    }

    return (
        <>
            <div className="items-in-cart">
                <div className="items-in-cart-icon-container" onClick={(e) => toggleEdit(!isEditing)}>
                    <Edit id="edit-small-icon" />   
                </div>
                <div className="items-in-cart-name-container">
                    <span className="items-in-cart-name">{items.name}</span>
                    <div className="items-in-cart-notes-container">
                        <span className="items-in-cart-notes-title">Catatan: </span>
                        {(isEditing ? 
                            <input className="edit-notes" type="text" placeholder="Tulis catatan untuk merchant..." onChange={(e) => dispatch(setNoteItems(items.id, e.target.value))} value={items.orderDesc}/> :
                            <span className="items-in-cart-notes">{items.orderDesc === null || items.orderDesc === '' ? '-' : items.orderDesc}</span>
                        )}
                    </div>
                </div>
                <div className="items-in-cart-counted-container">
                    <button className="cart-detail-button-counter" onClick={() => onItemRemoveFromCart(items.id)}><GoDash className="button-counter-icon" /></button>
                    <span className="items-in-cart-counted">x{items.qty}</span>
                    <button className="cart-detail-button-counter" onClick={() => onItemAddToCart(items.id)}><GoPlus className="button-counter-icon" /></button>
                </div>
                <div className="items-in-cart-price-container">
                    <span className="items-in-cart-price">{formatCurrency(items.price)}</span>
                </div>
            </div>
        </>
    )
}

export default ItemsInCart
