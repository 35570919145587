import React from 'react'
import PropType from 'prop-types';
import { AiFillStar, AiOutlineClockCircle } from 'react-icons/ai';
import { BiMapAlt } from 'react-icons/bi';
import './Merchant.css';

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';
import { MerchantImage } from '../../assets';

const SearchMerchantItem = ({ merchants }) => {
    const history = useHistory();
    const RatingStyle = {color: "#FFC00E" };

    return (
        <>
            {merchants.map((merchant, index) => (
                <div key={index} className="merchant-list-card" onClick={(e) => {history.push(`/merchant/${merchant.id}`)}}>
                    {merchant.is_closed === 0 ? 
                        <div className="img-container">
                            <img src={merchant.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                            {/* <div className="merchant-list-img-status">status</div> */}
                        </div>
                        :
                        <div className="merchant-list-img" style={{backgroundImage:"url(" + merchant.image + ")", marginRight:10}} >
                           <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}>{merchant.is_closed === 0 ? "Buka": "Tutup"}</p>
                        </div>
                    }
                    <div className="merchant-list-description-container">
                        <span className="merchant-list-name">{merchant.name}</span>
                        <span className="merchant-list-desc">{merchant.desc}</span>
                        <div className="border"></div>
                        <div className="merchant-list-detail-container">
                            <div className="merchant-list-detail"><AiFillStar className="merchant-list-detail-icon" style ={RatingStyle} /> <span>{merchant.avg_rating}</span></div>
                            <div className="merchant-list-detail"><BiMapAlt className="merchant-list-detail-icon" /> <span>{merchant.district}</span></div>
                            <div className="merchant-list-detail"><AiOutlineClockCircle className="merchant-list-detail-icon" /> <span>{formatCurrencyKStyle(merchant.min)} - {formatCurrencyKStyle(merchant.max)}</span></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

SearchMerchantItem.propTypes = {
    merchants: PropType.array.isRequired
}

export default SearchMerchantItem;
