import axios from 'axios';

const baseURL = 'https://brmarket-api.taximaxim.com/api/v1/home/merchant';

export const getMerchantsAPI = async (userID, lat, lon, city, host, name, addressName, type, page, day, time, param = null) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            day,
            time,
            ...(type === 'FILTER_NEAR_ME' ? { page, type: 0 } : 
                type === 'FILTER_DISTRICT' ? { page, district: param, type: 1 } : 
                type === 'SEARCH' ? { page, param } : 
                type === 'CATEGORY' ? { page, category: param } :
                { page })
        }
    });

    return res.data;
}

export const getMerchantsCategoryAPI = async (userID, lat, lon, city, host, name, addressName, type, page, category, day, time, param = null) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            category,
            day,
            time,
            ...(type === 'FILTER_NEAR_ME' ? { page, type: 0 } : 
                type === 'FILTER_DISTRICT' ? { page, district: param, type: 1 } : 
                type === 'SEARCH' ? { page, param } : 
                { page })
        }
    });

    return res.data;
}

export const getMerchantDetailsAPI = async (merchantId) => {
    const res = await axios.get('https://brmarket-api.taximaxim.com/api/v1/merchants/' + merchantId);
    return res.data;
}