import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const formatCurrency = (n) => {
    const format = new Intl.NumberFormat('id-ID');
  
    // or use toLocaleString()
    return format.format(n);
}

export const calculateTotal = (arr) => {
    if (!arr || arr?.length === 0) return 0;
  
    const total = arr.reduce((acc, val) => acc + val, 0);
  
    return total.toFixed(2);
}

export const formatCurrencyKStyle = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num)
}

export const actionMessage = (message, status = 'info') => {
    toast(message, {
        type: status,
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
    });

    <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

