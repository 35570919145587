import axios from 'axios';

const baseURL = 'https://brmarket-api.taximaxim.com/api/v1/history';

export const getHistoryAPI = async (userID, lat, lon, city, host, name, addressName, page) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
        }
    });

    return res.data;
}

export const updateRatingAPI = async (userID, lat, lon, city, host, name, addressName, orderId, rating) => {
    const res = await axios.post(baseURL + '/' + orderId, {
        userID,
        lat,
        lon,
        city,
        host,
        name, 
        addressName, 
        rating
    });

    return res.data;
}