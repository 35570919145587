import React from 'react'
import { Helmet } from 'react-helmet'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import Explore from '../Explore/Explore';

import {
    SearchTopNav,
    BottomSpace,
    BottomNav,
    MerchantDetailButton
} from '../components/common'
import { getMerchantDetails } from '../../application/actions/merchantActions';
import MerchantDetailItem from '../Merchant/MerchantDetailItem';
import ItemDetail from '../Merchant/ItemDetail';

const ExploreContainer = () => {
    const dispatch = useDispatch();
    
    const store = useSelector((state) => ({
        merchants: state.merchants,
        requestStatus: state.app.requestStatus,
        isLoading: state.app.loading
    }), shallowEqual);

    const [clickedDetail, setClickedDetail] = React.useState(false);
    const [detailData, setDetailData] = React.useState();
    const [selectedItemCat, setSelectedItemCat] = React.useState("None");

    const handleDetailClick = async (itemDetail, itemCat) => {
        if(itemDetail && itemCat) {
            await window.scrollTo(0, 0);
            setDetailData(itemDetail);
            setSelectedItemCat(itemCat);
            await dispatch(getMerchantDetails(itemDetail.merchants_id));
        }
        else {
            setDetailData();
            setSelectedItemCat("None");
        }
        setClickedDetail(!clickedDetail);
    }

    React.useEffect(() => {
    }, [clickedDetail])

    return (
        <>
            <Helmet>
                <title>{'Foods&Goods - Telusur'}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={'Telusuri merchant dan juga barang'} />
            </Helmet>
            <SearchTopNav isClicked={clickedDetail} handleDetailClick={handleDetailClick}/>

            {clickedDetail ? 
            <>
                <MerchantDetailItem merchant_details={store.merchants.details}/>
                <ItemDetail item={detailData} itemCat={selectedItemCat}/>
                <BottomSpace />
                <MerchantDetailButton />
            </> : 
            <>
                <Explore detailFunction={handleDetailClick}/>
                <BottomSpace />
                <BottomNav />
            </>}
        </>
    )
}

export default ExploreContainer
